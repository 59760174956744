import API from "./Config";
import ENDPOINTS from "./Enpoints";

const TripipeService = {
    get: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getUsers: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/get-users")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getLabels: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/s/get-labels")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getClients: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/s/get-clients")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getClientsVeh: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/s/get-clients-veh")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    updateDealsPosicion: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.TRIPIPE+"/change-pos", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    editTask: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.TRIPIPE+"/edit-task", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    addNewTask: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/add-task", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    addLabel: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/add-label", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    deleteLabel: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/delete-label", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    addNewNote: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/add-note", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    addContact: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/add-contact", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getTAsk: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    searchAll: (term) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/s/search?term="+term)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    verifyPlate: (plate) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/s/byplate/"+plate)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    addBitacora: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/add-bitacora", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getBitacora: (deal) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/get-bitacora/"+deal)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    editTaskDeal: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.TRIPIPE+"/edit-task-deal", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    editMemberDeal: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.TRIPIPE+"/edit-member-deal", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    editLabelDeal: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.TRIPIPE+"/edit-label-deal", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    editExtras: (data) => new Promise((resolve, reject) => {
        API.put(ENDPOINTS.TRIPIPE+"/edit-extras", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    editGastos: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/edit-gastos", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getGastos: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/get-gastos/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    deleteGastos: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/delete-gastos", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getVehicles: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/v/get-vehicles/")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getGastosAll: () => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/s/get-gastos-all")
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    setTareasInd: (data) => new Promise((resolve, reject) => {
        API.post(ENDPOINTS.TRIPIPE+"/v/set-tareas-ind/", data)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getTareasInd: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/v/get-tareas-ind/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
    getTareasIndAll: (page, limit, skip, filter) => new Promise((resolve, reject) => {
        API.get("tripipe/v/get-tareas-ind-all?page="+page+"&limit="+limit+"&skip="+skip+""+filter)
        .then(
            res => res.data
        )
            .then(
            data => resolve(data)
            )
            .catch(
            err => reject(err)
            )
    }),
    deleteTarea: (id) => new Promise((resolve, reject) => {
        API.get(ENDPOINTS.TRIPIPE+"/v/delete-tareas-ind/"+id)
        .then(
            res => res.data
        )
        .then(
            data => resolve(data)
        )
        .catch(
            err => reject(err)
        )
    }),
}

export default TripipeService;

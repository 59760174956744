import React, { useState, useEffect } from 'react';
import TripipeService from 'src/services/TripipeService';
import { Popconfirm, Modal, Form, Table, Input, Button, Select } from 'antd';
const { TextArea } = Input;

const Tareas = ({ task }) => {
    const [isModalTareasOpen, setIsModalTareasOpen] = useState(false);
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [users, setUsers] = useState([]);
    const [editingKey, setEditingKey] = useState("");

  // Función para saber si una fila está en edición
  const isEditing = (record) => record.id === editingKey;

  // Editar una fila
    const edit = (record) => {
        console.log(record)
        form.setFieldsValue({id: record.id, titulo: "", descripcion: "", responsable: '', dealId: task.deal_id, ...record });
        setEditingKey(record.id);
    };

    // Guardar los cambios en la fila editada
    const save = async (data) => {
        const row = await form.validateFields();
        if(data.key){
            try {
                
                const data = {
                    ...row,
                    dealId: task.deal_id 
                };
                TripipeService.setTareasInd(data).then(() =>{
                    const newData = [...dataSource];
                    const index = newData.findIndex((item) => data.key === item.key);
        
                    if (index > -1) {
                        newData[index] = { ...newData[index], ...row };
                        setDataSource(newData);
                        setEditingKey("");
                    }
                })            
            } catch (err) {
                console.log("Error:", err);
            }
        }else{
            let _data = {
                id: data.id,
                ...row
            }
            TripipeService.setTareasInd(_data).then(() =>{
                getTareas();
                getUsers();
                setEditingKey("");
            })   
        }        
    };

    // Cancelar la edición
    const cancel = () => {
        setEditingKey("");
    };

    // Eliminar una fila
    const handleDelete = async (record) => {
        try {
            TripipeService.deleteTarea(record.id).then(() =>{
                setDataSource(dataSource.filter((item) => item.id !== record.id));
            })            
        } catch (err) {
            console.log("Error:", err);
        }
    };

    // Agregar una nueva fila
    const handleAdd = () => {
        const newKey = `${dataSource.length + 1}`;
        setDataSource([
            ...dataSource,
            { key: newKey, titulo: '', descripcion: '', responsable: '' },
        ]);
    };

    // Definir las columnas de la tabla
    const columns = [
        {
            title: "Tarea",
            dataIndex: "titulo",
            editable: true,
            render: (text, record) =>
                isEditing(record) ? (
                    <Form.Item name="titulo" style={{ margin: 0 }}>
                        <Input placeholder='Tarea' />
                    </Form.Item>
                ) : (text),
        },
        {
            title: "Detalles",
            dataIndex: "descripcion",
            editable: true,
            render: (text, record) =>
                isEditing(record) ? (
                <Form.Item name="descripcion" style={{ margin: 0 }}>
                   <Input placeholder='Ingrese una descripción'/>
                </Form.Item>
                ) : (text),
        },
        {
            title: "Responsable",
            dataIndex: "responsable",
            editable: true,
            render: (text, record) =>
                isEditing(record) ? (
                <Form.Item name="responsable" style={{ margin: 0 }}>
                    <Select options={ users }></Select>
                </Form.Item>
                ) : getLabelById(text),
        },
        {
        title: "Acciones",
        dataIndex: "actions",
        render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
            <>
                <Button type="link" onClick={() => save(record)}>
                    Guardar
                </Button>
                <Popconfirm title="Cancelar?" onConfirm={cancel}>
                    <Button type="link">Cancelar</Button>
                </Popconfirm>
            </>
            ) : (
            <>
                <Button type="link" disabled={editingKey !== ""} onClick={() => edit(record)}>
                    Editar
                </Button>
                <Popconfirm title="Eliminar?" onConfirm={() => handleDelete(record)}>
                    <Button type="link" danger>
                        Eliminar
                    </Button>
                </Popconfirm>
            </>
            );
        },
        },
    ];

    const handleTareasOk = () =>{
        setDataSource([])
        setIsModalTareasOpen(false)  
    }

    const handleTareasCancel = () =>{
        setDataSource([])
        setIsModalTareasOpen(false)  
    }

    const showModal = () =>{
        getTareas();
        getUsers();
        setIsModalTareasOpen(true);
    }

    const getUsers = () =>{
        TripipeService.getUsers().then(resp =>{
            setUsers(resp.users)
        })
    }

    const getTareas = () =>{
        TripipeService.getTareasInd(task.deal_id).then(resp =>{
            console.log("Tareas", resp.tasks)
            setDataSource(resp.tasks)
        })
    }

    // Obtener el label de una opción por su id
    const getLabelById = (id) => {
        const option = users.find((opt) => opt.id === id);
        return option ? option.label : "";
    };

    useEffect(() => {  
        getTareas();
        getUsers();
    },[task])

    return(
        <>
            <Modal className='ctri-modal' title="Tareas independientes" width={800} open={isModalTareasOpen} cancelText="Cerrar" onOk={handleTareasOk} onCancel={handleTareasCancel}>
                <Form form={form} component={false}>
                    <button onClick={handleAdd} type="button" className='btn btn-sm btn-primary' style={{ marginBottom: 16 }}>
                        Agregar Tarea
                    </button>
                    <Table
                        components={{ body: { cell: EditableCell } }}
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                </Form>
            </Modal>
            <button type='button' className='btn btn-sm btn-primary' onClick={ showModal }>Tareas</button>
        </>
    )
}

// Componente EditableCell para celdas editables
const EditableCell = ({ editing, dataIndex, children, ...restProps }) => {
    return (
      <td {...restProps}>
        {editing ? <Form.Item name={dataIndex} style={{ margin: 0 }}>{children}</Form.Item> : children}
      </td>
    );
};

export default Tareas;
import React, {useRef, useEffect, useState} from 'react'
import { Form, Button, Col, Input, Checkbox, message } from 'antd';
import { CRow } from '@coreui/react';
import ActasService from 'src/services/ActasService';
import SignaturePad from 'react-signature-pad-wrapper';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import $ from "jquery";

const { TextArea } = Input;

const CreateActa = () => {
    const [form] = Form.useForm();
    const signaturePadRef = useRef(null);
    const signaturePadEmpRef = useRef(null);
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [showLot, setShowLot] = useState(false);

    const onFinish = (values) =>{
        if(signaturePadRef.current && signaturePadRef.current.isEmpty()){
            message.error(`Debe agregar una firma`);
        }else{ 
            $('body,html').stop(true,true).animate({				
                scrollTop: $('body').offset().top
            },100);
            setShowLot(true)
            let _data = {
                nombres: values.nombres,
                cedula: values.cedula,
                telefono: values.telefono,
                direccion: values.direccion,
                nombres_cliente: values.nombres_cliente,
                cedula_cliente: values.cedula_cliente,
                telefono_cliente: values.telefono_cliente,
                direccion_cliente: values.direccion_cliente,
                placa: values.placa,
                marca: values.marca,
                modelo: values.modelo,
                linea: values.linea,
                soat: values.soat ? true : false,
                tecnicomecanica: values.tecnicomecanica ? true : false,
                tarjeta: values.tarjeta ? true : false,
                manuales: values.manuales ? true : false,
                herramientas: values.herramientas ? true : false,
                perno_seguridad: values.perno_seguridad ? true : false,
                repuesto: values.repuesto ? true : false,
                kit_carretera: values.kit_carretera ? true : false,
                botiquin: values.botiquin ? true : false,
                gato: values.gato ? true : false,
                cant_llaves: values.cant_llaves,
                km_inicial: 0,
                observaciones: values.observaciones,
                created_by: authUser().id,
                firma_empleado: signaturePadEmpRef.current.toSVG(),
                firma_cliente: signaturePadRef.current.toSVG(),
                tipo: 'entrega',
                clase: values.clase,
                chasis: values.chasis,
                motor: values.motor,
                color: values.color,
                carroceria: values.carroceria,
                servicio: values.servicio
            }
            const formData = new FormData();
            Object.keys(_data).forEach((key) => {
                formData.append(key, _data[key]);
            });

            ActasService.save(formData).then(resp => {
                message.success("Acta de entrega creada exitosamente");
                setTimeout(() => {
                    setShowLot(false)
                    navigate("/actas-entrega")
                }, 4000);
            }).catch(() =>{
                setShowLot(false)
            })
        }
    }

    const searchPlate = (event) =>{
        if(event.target.id === 'basic_placa' && event.target.value.length === 6){
            ActasService.getByPlate(event.target.value).then(resp =>{
                let _data = resp.data
                if(_data.length > 0){
                    _data = _data[0]
                    let _fields = {
                        marca: _data.make,
                        linea: _data.model,
                        version: _data.version,
                        anho: _data.year,
                        clase: _data.clase,
                        chasis: _data.chasis,
                        motor: _data.motor,
                        color: _data.color,
                        carroceria: _data.vehicle_body_type,
                        servicio: _data.servicio
                    }
                    form.setFieldsValue(_fields)
                }
            })
        }
    }

    const clearSignature = () => {
        if (signaturePadRef.current) {
          signaturePadRef.current.clear();
        }
    };

    const clearSignatureEmp = () => {
        if (signaturePadEmpRef.current) {
            signaturePadEmpRef.current.clear();
        }
    };

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }else{
            let _fields = {
                cedula: authUser().cedula,
                nombres: authUser().names,
                telefono: authUser().phone,
                direccion: 'Cra 13a #93-91'
            }
            form.setFieldsValue(_fields)
        }
    }, []);

    return(
            <div className='no-select'>
                <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" /> 
            </div> 
            <CRow>
                <Col span={24} className='text-center my-4'>
                    <h1>Crear acta de entrega</h1>
                </Col>
            </CRow>
            <Form name="basic" form={form} onFinish={onFinish} layout='vertical' onChange={ searchPlate }>
                <CRow>
                    <Col md={24} xs={24}>
                        <h4>Información del empleado</h4>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Nombres y Apellidos" name="nombres"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Nombres y apellidos' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Cédula de ciudadania" name="cedula"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Cédula de ciudadania' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Teléfono" name="telefono"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 3134569875' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Dirección" name="direccion"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ingrese la dirección' />
                        </Form.Item>
                    </Col>                    
                </CRow>
                <hr/>
                <CRow>
                    <Col md={24} xs={24}>
                        <h4>Información del cliente</h4>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Nombres y Apellidos" name="nombres_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Nombres y apellidos' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Cédula de ciudadania" name="cedula_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Cédula de ciudadania' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Teléfono" name="telefono_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 3134569875' />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Dirección" name="direccion_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ingrese la dirección' />
                        </Form.Item>
                    </Col>
                </CRow>
                <hr/>
                <CRow>
                    <Col md={8} xs={24}>
                        <Form.Item label="Placa" name="placa"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: RNR549' />
                        </Form.Item>
                    </Col> 
                    <Col md={8} xs={24}>
                        <Form.Item label="Marca" name="marca"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Mercedes-Benz' />
                        </Form.Item>
                    </Col> 
                    <Col md={8} xs={24}>
                        <Form.Item label="Año" name="modelo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 2022' />
                        </Form.Item>
                    </Col> 
                    <Col md={8} xs={24}>
                        <Form.Item label="Linea" name="linea"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: SLK 200' />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Version" name="version"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Clase SLK' />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Clase" name="clase"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Clase SLK' />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Chasis" name="chasis"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej:0011010101' />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Motor" name="motor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej:0011010101' />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Color" name="color"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Negro' />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Carroceria" name="carroceria"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: SEDAN' />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Servicio" name="servicio"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Particular' />
                        </Form.Item>
                    </Col>
                </CRow>
                <hr />
                <CRow>
                    <Col md={24} xs={24}>
                        <p>En este acto, se hace entrega material del vehículo, de acuerdo con las siguientes condiciones: </p>
                    </Col>
                    <Col md={24} xs={24}>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th> Documento </th>
                                        <th className='text-center'> Vigente </th>
                                    </tr>
                                </thead>
                                <tbody className='table-group-divider'>
                                    <tr>
                                        <td>SOAT</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="soat" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Revisión Tecnicomecanica</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="tecnicomecanica" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col md={24} xs={24}>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th colSpan={2} className='text-center'>INVENTARIO DEL VEHICULO </th>
                                    </tr>
                                </thead>
                                <tbody className='table-group-divider'>                                    
                                    <tr>
                                        <td>Tarjeta de Propiedad</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="tarjeta" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Manuales del Propietario</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="manuales" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Herramientas del Vehículo</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="herramientas" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Llave de pernos de seguridad</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="perno_seguridad" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Llanta de Repuesto </td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="repuesto" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Kit de Carretera </td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="kit_carretera" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Botiquín</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="botiquin" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Gato</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="gato" valuePropName="checked">
                                                <Checkbox></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center' colSpan={2}>
                                            <Form.Item className='my-0' label="Llaves" name="cant_llaves"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Este campo es requerido!',
                                                    }
                                                ]}>
                                                <Input type='number' className='text-end' placeholder='Ej: 2' />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center' colSpan={2}>
                                            <Form.Item className='my-0' label="Otros/ Observaciones adicionales" name="observaciones">
                                                <TextArea rows={2} />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </CRow>
                <hr />
                <CRow className="ffirmas">
                    <Col span={24} xs={24} className='text-center mx-auto'>
                        <p className='mb-0'><strong>Firma del cliente</strong></p>
                        <SignaturePad width={400} height={200} ref={signaturePadRef} 
                            options={{
                                penColor: 'rgb(0, 0, 0)',
                                minWidth: 0.5, // Grosor mínimo del trazo
                                maxWidth: 2.5, // Grosor máximo del trazo
                                throttle: 16,  // Reduce los eventos para mejorar la suavidad
                                velocityFilterWeight: 0.7 // Ajusta el peso de la velocidad del trazo
                            }} />
                    </Col>
                    <Col span={24} xs={24} className='text-center'>
                        <button type='button' onClick={clearSignature}>Borrar firma</button>
                    </Col>
                </CRow>
                <CRow className="ffirmas mt-4">
                    <Col span={24} xs={24} className='text-center mx-auto'>
                        <p className='mb-0'><strong>Firma del empleado</strong></p>
                        <SignaturePad width={400} height={200} ref={signaturePadEmpRef} 
                        options={{
                            penColor: 'rgb(0, 0, 0)',
                            minWidth: 0.5, // Grosor mínimo del trazo
                            maxWidth: 2.5, // Grosor máximo del trazo
                            throttle: 16,  // Reduce los eventos para mejorar la suavidad
                            velocityFilterWeight: 0.7 // Ajusta el peso de la velocidad del trazo
                        }}  />   
                    </Col>
                    <Col span={24} xs={24} className='text-center'>
                        <button type='button' onClick={clearSignatureEmp}>Borrar firma</button>
                    </Col>
                </CRow>
                <CRow className='my-4'>
                    <Col span={24} xs={24} md={24} className='text-center'>
                        <Button type='primary'  htmlType="submit">Generar</Button>
                    </Col>
                </CRow>
            </Form>
        </div>    
    )
}

export default CreateActa;

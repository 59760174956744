import { Col, Row, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import TripipeService from 'src/services/TripipeService';
import DataTable from 'react-data-table-component';
import Moment from 'moment'
import { EyeOutlined} from '@ant-design/icons';

const Tareas = () => {
    const [tasks, setTasks] = useState([])
    const [responsables, setResponsables] = useState([])
    const [task, setTask] = useState({})
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        "search" : '',
        'responsable' :'' 
    })

    const columns = [
        {
            name: 'Columna',
            selector: rowData => rowData.titleCol
        }, 
        {
            name: 'Responsable',
            selector: rowData => rowData.names
        },
        {
            name: 'Tarea',
            selector: rowData => rowData.titulo
        },
        {
            name: 'Vehículo',
            selector: rowData => rowData.title
        },
        {
            name: 'Placa',
            selector: rowData => <strong>{rowData.plate.toUpperCase()}</strong>
        },
        {
            name: 'Creado el',
            selector: rowData => Moment(rowData.createdAt).format("DD/MM/YYYY h:mm:ss a"),
            sortable: true,
        }, 
        {
            name: 'Acciones',
            selector: rowData => {
            return <div className='d-flex justify-content-between'>
                <div className='text-center cc1' onClick={ () => showData(rowData) }>
                    <EyeOutlined className='iconAprobar' title='Ver'/>
                </div>
            </div>           
            }
        }
    ];

    const showData = (data) =>{
        setTask(data)
        setIsModalOpen(true);
    } 

    const saveField = (event) =>{
        let _filters = filters;
        switch (event.target.name) {
          case 'search':
            filters.search = event.target.value
            break;
          default:
            break;
        }
        setFilters(_filters)
        getAll(1)
    }

    const handlePageChange = page => {
        setTasks(page);
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getAll(page);    
        setLoading(false)
    };
    const getUsuarios = () =>{
        TripipeService.getUsers().then(resp =>{
            setResponsables(resp.users)
        })
    }
    const getAll = (ppage) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        if(filters.responsable !== '') _filter += '&responsable='+filters.responsable;
        TripipeService.getTareasIndAll(ppage,limit,skip,_filter).then(resp =>{
            let _data = resp.data;            
            setTotalRows(resp.total);
            setTasks(_data);
            setLoading(false);
        })
    }
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const changeResponsable = (event) =>{
        let _value = event.target.value;
        let _filters = filters;
        if(_value !== ''){
            filters.responsable = _value;
            setFilters(_filters)
            getAll(1, limit)
        }else{
            filters.responsable = '';
            setFilters(_filters)
            getAll(1, limit)
        }
    }

    const obtenerUsuarios = (items) => {
        const usuariosUnicos = [...new Set(items.map(item => item.names))];
        //return usuariosUnicos;
        return usuariosUnicos.map(usuario => ({ label: usuario, value: usuario }));
    };

    useEffect(() => {  
        getAll(page, limit, skip);
        getUsuarios();
        
    },[])

    return(
        <>
            <Modal title="Detalle" open={isModalOpen} cancelText="Cerrar" onCancel={handleOk} onOk={handleOk} okText={"Aceptar"}>
                <ul>
                  <li>Tarea: { task.titulo }</li>
                  <li>Descripción: { task.descripcion }</li>
                  <li>Responsable: { task.names }</li>
                  <li>Vehículo: { task.title}</li>
                  <li>Placa: <strong>{ task.plate ? task.plate.toUpperCase() : '' }</strong></li>
                  <li>Fecha de creación: { Moment(task.createdAt).format("DD/MM/YYYY h:mm:ss a") }</li>
                </ul>
            </Modal>
            <Row>
                <Col span={24} className='text-center mb-3'>
                    <h3>Tareas</h3>
                </Col>
            </Row>
            <Row>
                <Col span={24} className='text-end'>
                    <div className="d-flex align-items-end">
                        <div className='form-group w-100 text-end me-4'>
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <input type="search" className='form-control m-150' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
                            </span>
                        </div>
                        <div className='form-group'>
                            <label style={{ fontSize: '12px'}}>Responsable</label>
                            <select className='form-select form-select-sm' onChange={changeResponsable} name='select_resp'  style={{ width: '200px' }}>
                                <option value="">Seleccione el responsable</option>
                                {
                                    responsables.length > 0 ? responsables.map((item) => (
                                        <option value={item.label}>{ item.label }</option>
                                    )): ''
                                }
                            </select>
                        </div>
                    </div>
                </Col>
                <Col span={24} className='my-4'>
                    <DataTable
                        noDataComponent={"No se han encontrado resultados"}
                        columns={ columns }
                        data={ tasks }
                        progressPending={loading}
                        pagination 
                        paginationServer
                        responsive             
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </Col>
            </Row>
        </>
    )
}
export default Tareas;
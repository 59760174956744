import React, { useRef, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TripipeService from 'src/services/TripipeService';
import ActasService from 'src/services/ActasService';
import VehiculosService from 'src/services/VehiculosService';
import { Col, Modal, Form, message, Input, Select, Button, Checkbox, Flex, Progress, Tag, Collapse, ColorPicker, Upload } from 'antd';
import { Link  } from "react-router-dom";
import $ from "jquery";
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import Moment from 'moment'
import { CRow } from '@coreui/react';
import DataTable from 'react-data-table-component'
import { MinusCircleOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import Tareas from './tareasInd';
const Archivos = React.lazy(() => import("../vehiculos/Archivos"));

const { TextArea } = Input;
const { Panel } = Collapse;
const moneyFormat = (value) => "COP "+ new Intl.NumberFormat().format(value);
const moneyFormatWithoutCOP = (value) => new Intl.NumberFormat().format(value);

const BitacoraAccordion = ({ bitacora }) => {
    return (
      <Collapse accordion>
        <Panel header="Actividad" key="1">
          <Col span={24}>
            {bitacora.length > 0 ? bitacora.map((item, index) => (
              <dl key={`bitacora-${index}`} className='mb-0 border-bottom'>
                <dt style={{ fontWeight: '400' }} className='mb-1'>
                  <b>{item.names}: </b> {item.descripcion}
                  <span style={{ float: 'right', fontSize: '12px' }}>
                    {Moment(item.created_at).format("DD/MM/YYYY h:mm:ss a")}
                  </span>
                </dt>
              </dl>
            )) : <p>No hay elementos en la bitácora</p>}
          </Col>
        </Panel>
      </Collapse>
    );
};
  
const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    if(value){
        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
        <Tag
            color={value}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
            marginInlineEnd: 4,
            }}
        >
            {label}
        </Tag>
        );
    }
};

const Index = () => {
    const [data, setData] = useState(null);
    const [columnSelected, setColumnSelected] = useState({title: ''});
    const [taskSelected, setTaskSelected] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalLabelOpen, setIsModalLabelOpen] = useState(false);
    const [isModalGasOpen, setIsModalGasOpen] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [isLoading, setIsLoading] = useState(true); 
    const [isEdit, setIsEdit] = useState(false); 
    const [notes, setNotes] = useState([]); 
    const [labels, setLabels] = useState([]); 
    const [users, setUsers] = useState([]); 
    const [clients, setClients] = useState([]); 
    const [dataLabels, setDataLabels] = useState([]);
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [form_create] = Form.useForm();
    const [flabel] = Form.useForm();
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [ifTasks, setIfTasks] = useState(false);
    const [porcTasks, setPorcTasks] = useState(0);
    const [bitacora, setBitacora] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [openDesc, setOpenDesc] = useState(true);
    const [gastos, setGastos] = useState(0);
    const [form] = Form.useForm();
    const [products, setProducts] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [gastosAll, setGastosAll] = useState([]);
    const [gasto, setGasto] = useState('');
    const [color, setColor] = useState("#FFFFFF");
    const [isActive, setIsActive] = useState(true);
    const archivosRef = useRef();
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [taskFull, setTaskFull] = useState({});

    const props = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
    };

    const toggleTextarea = (index) => {
        setProducts(products.map((product, i) => 
          i === index ? { ...product, textareaVisible: !product.textareaVisible } : product
        ));
    };

    const handleColorChange = (value) => {
        setColor(value.toHexString());
        flabel.setFieldValue('color', value.toHexString())
    };

    const columns = [
        {
            name: 'Nombre',
            selector: rowData => rowData.name
        }, 
        {
            name: 'Color',
            selector: rowData => {
                return <div style={{
                    backgroundColor: rowData.color, 
                    width: "80px", 
                    height: "25px", 
                    borderRadius: "5px", 
                    border: "1px solid #ccc",
                    color: "#FFF",
                    textAlign: "center"
                  }}>
                    { rowData.color }
                </div>
            }
        },
        {
            name: 'Acciones',
            selector: rowData => {
                return <div className='d-flex justify-content-between'>
                    {/* <Link onClick={ () => editarLabel(rowData) }>
                        Editar
                    </Link> */}
                    &nbsp;&nbsp;
                    <Link onClick={ () => eliminarLabel(rowData) }>
                        Eliminar
                    </Link> 
                </div>
            }
        }
    ]
    
    const eliminarLabel = (_label) =>{
        TripipeService.deleteLabel({id: _label.id}).then(resp =>{
            getLabels();
        })
    }

    const showModalEtiquetas = () =>{
        setIsModalLabelOpen(true)
    }

    const handleEtiCancel = () =>{
        setIsModalLabelOpen(false)
    }

    const showModalGastos = async () =>{
        try {
            setProducts([])
            const resp = await TripipeService.getGastos(taskSelected.deal_id);
            setTimeout(() => {
                setProducts(resp.results);
                setIsModalGasOpen(true); 
            }, 500);            
        } catch (error) {
            console.error("Error al obtener los gastos:", error);
        }       
    }

    const handleGasCancel = () =>{
        setProducts([])
        setTimeout(() => {            
            setIsModalGasOpen(false)
        }, 500);   
    }

    const decodeHtmlEntities = (html) => {
        console.log(html)
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.body.textContent || "";
    };

    
    const decodeHtml = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html");
        return doc.documentElement.textContent;
    };

    const showModal = (task) =>{ 
        getVehicles();        
        setTaskSelected(task)  
        setIsEdit(true)
        $("[name=notas_add]").val('')
        $("[name=inputSearch]").val('')
        setResults([])
        TripipeService.getTAsk(task.deal_id).then(resp =>{
            setNotes(resp.result.notes)
            setTaskFull(resp.result.task[0])
            setIfTasks(false)
            let _task = resp.result.task[0]
            let _title2 = _task.column_id == 11 ? '(Archivada)' : '';
            let title = <a
                href={"/vehiculos/ver/"+_task.carId}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#1890ff', textDecoration: 'underline' }}
            >
                { task.content+" - "+task.placa.toUpperCase()+" "+_title2 }
            </a>;
            setTitleModal(title);
            let _columns = data.columns
            let _column = _columns[task.column]
            setColumnSelected(_column)
            let cleanedTasks = [];
            if(_task.tareas && _task.tareas.length > 0){
                cleanedTasks = _task.tareas.map(item => ({
                    ...item,
                    task: decodeHtml(item.task),
                }));
                setTasks(cleanedTasks)
                setIfTasks(true)
                const totalTasks = _task.tareas.length;
                const completedTasks = _task.tareas.filter(task => task.completed === true).length;
                const completionPercentage = (completedTasks / totalTasks) * 100;
                setPorcTasks(completionPercentage.toFixed(2))
            }
            let _fields = {
                marca: _task.make,
                modelo: _task.year,
                linea: _task.model,
                placa: _task.title+" - "+_task.plate,
                costo: moneyFormat(_task.price),
                name_contact: _task.contact ? _task.contact : '',
                miembros: _task.miembros ? (_task.miembros): [],
                etiquetas: _task.labels ? (_task.labels): [],
                tasks: cleanedTasks ? cleanedTasks : [],
                descripcion: _task.descripcion && _task.descripcion != 'undefined' ? _task.descripcion : '',
                precio_minimo: _task.precio_min && _task.precio_min != 'undefined' ? _task.precio_min : '',
                porc_comision: _task.porc_comision && _task.porc_comision != 'undefined' ?  _task.porc_comision : '',
                fecha_soat: _task.fecha_soat && _task.descripcion != 'undefined' ? _task.fecha_soat : '',
                fecha_rtm: _task.fecha_rtm && _task.fecha_rtm != 'undefined' ? _task.fecha_rtm : ''
            }
            setGastos(moneyFormat(_task.totalGastos));
            setLabels(_task.labels)
            form_create.setFieldsValue(_fields)

            TripipeService.getBitacora(task.deal_id).then(bit =>{
                setBitacora(bit.results)
            })
        })
        setIsModalOpen(true)
    }

    const handleOk = () => {      
        form_create
        .validateFields()
        .then((values) => {   
            let _selectd = vehicles.find(x => x.id == values.placa);        
            let data = {
                placa: _selectd.plate,
                marca: values.marca,
                modelo: values.modelo,
                linea: values.linea,
                costo: values.costo.replace(/\s|,|COP/g, ''),
                user_id: authUser().id,
                column_id: columnSelected.id,
                contact: values.name_contact ? values.name_contact : 0,
                labels: values.etiquetas ? values.etiquetas : [],
                miembros: values.miembros ? values.miembros : [],
                tareas: values.tasks ? JSON.stringify(values.tasks, null, 2): [],
                descripcion: values.descripcion,
                precio_minimo: values.precio_minimo,
                porc_comision: values.porc_comision,
                fecha_soat: values.fecha_soat,
                fecha_rtm: values.fecha_rtm,
                gastos: values.gastos
            }
            console.log(isEdit, data);

            if(!isEdit){
                TripipeService.addNewTask(data).then(() =>{
                    setIsEdit(true)
                    message.success("Guardado exitosamente");
                    getData();
                })
            }
            
            form_create.resetFields();
            setLabels([])
            setNotes([])
            TripipeService.get().then(resp => {
                setData(resp.result);  
                setIsLoading(false);  
            }).catch(error => {
                console.error('Error al obtener los datos:', error);
                setIsLoading(false); 
            });
            setIsModalOpen(false);
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    };

    const handleCancel = () => {
        getData();
        setLabels([])
        setNotes([])
        form_create.resetFields();
        setIsModalOpen(false);
    };

    const onDragEnd = (result) =>{
        const { destination, source, draggableId } = result;
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const startColumn = data.columns[source.droppableId];
        const finishColumn = data.columns[destination.droppableId];

        if (startColumn === finishColumn) {
            const newTaskIds = Array.from(startColumn.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...startColumn,
                taskIds: newTaskIds,
            };

            const newData = {
                ...data,
                columns: {
                  ...data.columns,
                  [newColumn.id]: newColumn,
                },
            };
        
            setData(newData);
        } else {
            const startTaskIds = Array.from(startColumn.taskIds);
            startTaskIds.splice(source.index, 1);
            const newStartColumn = {
              ...startColumn,
              taskIds: startTaskIds,
            };
      
            const finishTaskIds = Array.from(finishColumn.taskIds);
            finishTaskIds.splice(destination.index, 0, draggableId);
            const newFinishColumn = {
              ...finishColumn,
              taskIds: finishTaskIds,
            };
      
            const newData = {
              ...data,
              columns: {
                ...data.columns,
                [newStartColumn.id]: newStartColumn,
                [newFinishColumn.id]: newFinishColumn,
              },
            };
      
            setData(newData);
        }
        let column = destination.droppableId.split("-")[1];
        let task = draggableId.split("_")[1];
        let _data = {
            'column_id': column,
            'task_id': task
        }
        TripipeService.updateDealsPosicion(_data).then(resp => console.log(resp))
    }
    
    const addTask = (_column) =>{
        getVehicles();
        setLabels([])
        setNotes([])
        setIsEdit(false)
        setColumnSelected(_column)
        form_create.resetFields();
        setTitleModal("Nuevo Deal en "+_column.title);
        setIsModalOpen(true);
    }

    const addNote = () =>{
        if($("[name=notas_add]").val() !== '' && $("[name=notas_add]").val().length > 4){
            let _nota = {
                note: $("[name=notas_add]").val(),
                user_id: authUser().id,
                tri_deal_id: taskSelected.deal_id
            }
            TripipeService.addNewNote(_nota).then(resp =>{
                message.success("Nota agregada con éxito");
                TripipeService.getTAsk(taskSelected.deal_id).then(resp =>{
                    setNotes(resp.result.notes)
                    $("[name=notas_add]").val('')
                    TripipeService.getBitacora(taskSelected.deal_id).then(bit =>{
                        setBitacora(bit.results)
                    })
                })
            })
        }else{
            message.error("No se puede crear una nota vacia o de menos de 4 caracteres");
        }
    }

    const searchPlate = (event) =>{
        if(event.target.id === 'basic_placa' && event.target.value.length === 6){
            TripipeService.verifyPlate(event.target.value).then(resp1 =>{
                let _existPlate = resp1.results;
                if(_existPlate.length === 0){
                    ActasService.getByPlate(event.target.value).then(resp =>{
                        let _data = resp.data
                        if(_data.length > 0){
                            _data = _data[0]
                            let _fields = {
                                marca: _data.make,
                                modelo: _data.year,
                                linea: _data.model,
                                costo: moneyFormat(_data.price)
                            }
                            form_create.setFieldsValue(_fields)
                        }
                    })
                }else{
                    message.error('La placa seleccionada ya se encuentra registrada en Tripipe')
                }
            })
        }
    }

    const getUsers = () =>{
        TripipeService.getUsers().then(resp =>{
            setUsers(resp.users)
        })
    }

    const getLabels = () =>{
        TripipeService.getLabels().then(resp =>{
            setDataLabels(resp.labels)
        })
    }

    const getClients = () =>{
        TripipeService.getClients().then(resp =>{
            setClients(resp.users)
        })
    }

    const onSaveLabel = () =>{
        flabel
        .validateFields()
        .then((values) => {
            console.log(values)            
            let _data = {
                name: values.nombre,
                color: values.color
            }
            TripipeService.addLabel(_data).then(() =>{
                flabel.resetFields();
                getLabels()
            })
        })
        .catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    const search = (event) =>{
        if (event.target.value) {
            TripipeService.searchAll(event.target.value).then(resp =>{
                setResults(resp.results)
                setShowResults(true);
            })
        } else {
            setResults([]);
            setShowResults(false);
        }
    }

    const handleBlur = () => {
        setTimeout(() => setShowResults(false), 100); // Añade un pequeño retraso antes de cerrar el menú para permitir hacer clic en los resultados
    };

    const handleFocus = () => {
        if (results.length > 0) {
          setShowResults(true);
        }
    };
    
    const getData = () => {
        TripipeService.get().then(resp => {
          setData(resp.result);  // Guarda los datos obtenidos
          setIsLoading(false);  // Cambia el estado de carga a false
        }).catch(error => {
          console.error('Error al obtener los datos:', error);
          setIsLoading(false);  // Cambia el estado de carga a false incluso si hay un error
        });
    };

    const getVehicles = () => {
        TripipeService.getVehicles().then(resp => {
          setVehicles(resp.results);  // Guarda los datos obtenidos
        }).catch(error => {
          console.error('Error al obtener los datos:', error);
        });
    };

    const saveMember = () =>{
        if(isEdit){
            let data = {
                user_id: authUser().id,
                id: taskSelected.deal_id,
                members: form_create.getFieldValue('miembros'),
                estadoTarea: `Actualizó la lista de miembros.`
            }
            TripipeService.editMemberDeal(data).then(() =>{
                message.success('Tarea actualizada')
                TripipeService.getBitacora(taskSelected.deal_id).then(bit =>{
                    setBitacora(bit.results)
                })
            })
        }
    }

    const saveField = (field, label) =>{
        if(isEdit){
            let data = {
                user_id: authUser().id,
                id: taskSelected.deal_id,
                field: field,
                value: form_create.getFieldValue(field),
                estadoTarea: `Actualizó ${label}.`
            }
            TripipeService.editExtras(data).then(() =>{
                message.success('Tarea actualizada')
                TripipeService.getBitacora(taskSelected.deal_id).then(bit =>{
                    setBitacora(bit.results)
                })
            })
        }
    }

    const saveLabel = () =>{
        if(isEdit){
            let data = {
                user_id: authUser().id,
                id: taskSelected.deal_id,
                labels: form_create.getFieldValue('etiquetas'),
                estadoTarea: `Actualizó la lista de etiquetas.`
            }
            TripipeService.editLabelDeal(data).then(() =>{
                message.success('Tarea actualizada')
                TripipeService.getBitacora(taskSelected.deal_id).then(bit =>{
                    setBitacora(bit.results)
                })
            })
        }
    }

    const handleCheckboxChange = (checked, index) => {
        // Actualizar la tarea en el estado
        setBitacora([])
        const updatedTasks = [...tasks];
        updatedTasks[index].completed = checked;
        let data = {
            user_id: authUser().id,
            id: taskSelected.deal_id,
            tareas: JSON.stringify(form_create.getFieldValue('tasks'), null, 2),
            estadoTarea: `La tarea ${updatedTasks[index].task} fue marcada como ${ checked ? 'completada.' : 'no completada.'}.`
        }
        TripipeService.editTaskDeal(data).then(() =>{
            message.success('Tarea actualizada')
            TripipeService.getBitacora(taskSelected.deal_id).then(bit =>{
                setBitacora(bit.results)
                const totalTasks = updatedTasks.length;
                const completedTasks = updatedTasks.filter(task => task.completed === true).length;
                const completionPercentage = (completedTasks / totalTasks) * 100;
                setPorcTasks(completionPercentage.toFixed(2))
            })
        })
    };

    const isTaskCompleted = (index) => {
        const values = form_create.getFieldValue('tasks') || [];
        return values[index]?.completed;
    };

    const deleteGastos = (item) =>{
        const newData = {
            user_id: authUser().id,
            ...item,
        };
        TripipeService.deleteGastos(newData).then(() =>{
            TripipeService.getGastos(taskSelected.deal_id).then(resp =>{
                setProducts([]);
                setTimeout(() => {
                    setProducts(resp.results);
                }, 500);
                setGastos(resp.results.length > 0 ? moneyFormat(resp.results[0].totalGastos) : 'COP 0')
                form.resetFields();
            })
        })
    }

    const cleanNumber = (num) => {
        console.log(num)
        if (!num) return 0; // Manejo de valores vacíos o nulos
        return Number(num.replace(/\,/g, '')); // Elimina los puntos y convierte a número
    };
    
    const saveProduct = async (index) => {
        try {
            let newData = {};
            if (index === -1) {
                if(document.querySelector(`[name=pprecio-nuevo]`)?.value !== '' && gasto != ''){
                    newData = {
                        isEdit: false,
                        deal_id: taskSelected.deal_id,
                        nota: '',
                        precio: document.querySelector(`[name=pprecio-nuevo]`)?.value || 0,
                        producto: gasto,
                        user_id: authUser().id
                    };
                    setTimeout(() => {
                        setGasto('')
                        document.querySelector(`[name=pprecio-nuevo]`).value = "";
                        document.querySelector(`[name=pproducto-nuevo]`).value = "";
                    }, 500);
                    // Guardar cambios
                    await TripipeService.editGastos(newData);
            
                    // Obtener lista actualizada
                    const resp = await TripipeService.getGastos(taskSelected.deal_id);
                    setProducts([]);
                    setTimeout(() => {
                        setProducts(resp.results);
                        getGastosAll();
                    }, 500);
                    setGastos(resp.results.length > 0 ? moneyFormat(resp.results[0].totalGastos) : 'COP 0');
                }
            } else {
                let _product = products[index];
    
                newData = {
                    isEdit: true,
                    deal_id: taskSelected.deal_id,
                    nota: document.querySelector(`[name=pnota${index}]`)?.value || '',
                    precio: cleanNumber(document.querySelector(`[name=pprecio${index}]`)?.value || 0),
                    producto: document.querySelector(`[name=pproducto${index}]`)?.value || '',
                    user_id: authUser().id,
                    id: _product.id
                };

                // Guardar cambios
                await TripipeService.editGastos(newData);
        
                // Obtener lista actualizada
                const resp = await TripipeService.getGastos(taskSelected.deal_id);
                setProducts([]);
                setTimeout(() => {
                    setProducts(resp.results);
                }, 500);
                setGastos(resp.results.length > 0 ? moneyFormat(resp.results[0].totalGastos) : 'COP 0');
            }    
            
        } catch (error) {
            console.error("Error guardando el producto:", error);
        }
    };

    const onSelectCar = (value) =>{
        if(value.length > 0){
            let _data = vehicles.find(x => x.id === value[0])

            let _fields = {
                marca: _data.make,
                modelo: _data.year,
                linea: _data.model,
                costo: moneyFormat(_data.price)
            }
            form_create.setFieldsValue(_fields)
        }
    }

    const archivar = (column) =>{
        let task = taskSelected.deal_id;
        let _data = {
            'column_id': column,
            'task_id': task
        }
        
        TripipeService.updateDealsPosicion(_data).then(resp => getData())
        setTimeout(() => {
            setIsModalOpen(false)
        }, 1000);
    }
    
    const getGastosAll = () =>{
        TripipeService.getGastosAll().then(resp =>{
            setGastosAll(resp.results)
        })
    }

    const changeGasto = (value) =>{
        value = value[0]
        setGasto(value)
    }

    const handleUpload = async () => {    
        setUploading(true);
        let names = [];
        const uploadPromises = fileList.map((element, index) => {
            const data = new FormData();
            data.append('file', element);
            names[index] = element.name;
            return VehiculosService.uploadFile(data, `archivos_${taskSelected.placa}`);
        });

        // Espera a que todas las imágenes se suban
        const responses = await Promise.all(uploadPromises);
        
        // Extrae las URLs de las imágenes
        const images = responses.map(response => response.uri);
        
        images.forEach((element, index) => {
            let _data = {
                name: names[index],
                url: element,
                user_id: authUser()?.id              
            }
            VehiculosService.setArchivos(_data, taskSelected.id).then(resp =>{
                setFileList([]);
                message.success('Archivo(s) subidos correctamente.');
                setUploading(false);
                if (archivosRef.current) {
                    archivosRef.current.reload();
                }
            })
        });
    };

    const fetchData = () =>{
        getData();
        getLabels();
        getClients();
        getGastosAll();
    }

    useEffect(() => {
        let interval;
        if (isActive) {
            fetchData();
            interval = setInterval(fetchData, 120000); //Dos minutos
        }
        
        return () => clearInterval(interval);
    }, [isActive]);

    useEffect(() => {  
        if(!authUser().id){
            navigate('/')
        }        
        getUsers();
        getLabels();
        getClients();
        getGastosAll();
    },[])

    useEffect(() => {
        const handleActivity = () => setIsActive(true);
        const handleInactivity = () => setIsActive(false);
    
        window.addEventListener("mousemove", handleActivity);
        window.addEventListener("keydown", handleActivity);
    
        const inactivityTimer = setTimeout(handleInactivity, 30000); // 30 seg de inactividad
    
        return () => {
          window.removeEventListener("mousemove", handleActivity);
          window.removeEventListener("keydown", handleActivity);
          clearTimeout(inactivityTimer);
        };
      }, []);

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    return(
        <div>            
            <Modal className='ctri-modal' title="Gastos" width={800} open={isModalGasOpen} onOk={handleGasCancel} onCancel={handleGasCancel} okText="Cerrar" cancelButtonProps={{ style: { display: 'none' } }}>
                <div className="product-table">
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Producto</th>
                            <th>Precio</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody id="gastos-body">
                        {products.map((product, index) => ( 
                            <>
                            <tr key={"producto"+index}>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name={`pproducto${index}`}
                                        defaultValue={product.producto}
                                        onBlur={() => saveProduct(index)}
                                    />                       
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name={`pprecio${index}`}
                                        defaultValue={ moneyFormatWithoutCOP(product.precio) }
                                        onBlur={() => saveProduct(index)}
                                    />
                                </td>
                                <td>
                                    <svg style={{ cursor: 'pointer'}} title="Agregar nota" onClick={() => toggleTextarea(index)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-journal" viewBox="0 0 16 16">
                                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2"/>
                                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z"/>
                                    </svg>
                                    &nbsp;
                                    <svg style={{ cursor: 'pointer'}} title="Eliminar" onClick={ () => deleteGastos(product) } xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                    </svg>
                                </td>
                            </tr>
                            {
                                product.textareaVisible ? 
                                <tr>
                                    <td colSpan={2}>
                                        <div>
                                            <textarea className="form-control" placeholder="Nota" name={`pnota${index}`} id="floatingTextarea" defaultValue={product.nota} 
                                                onBlur={() => saveProduct(index)}>
                                            </textarea>
                                        </div>
                                    </td>                                
                                </tr> : null
                            }                            
                            </>
                        ))}                        
                        {
                            products.length > 0 ?
                            <tr>
                                <td colSpan={3} className='text-end'>
                                    <span className='mb-0'>Total ........ <strong>{ (gastos) }</strong></span>
                                </td>
                            </tr> : 
                            <tr colSpan={4} className='text-center'>
                                <span>No existen productos agregados</span>
                            </tr>
                        }
                        </tbody>
                    </table>
                    <br/>
                    <table>
                        <tbody>
                            <tr key={"producto-nuevo"}>
                                <td>
                                    <Select
                                        style={{ minWidth: '200px' }}
                                        showSearch
                                        placeholder="Seleccione un producto"
                                        optionFilterProp="label"
                                        onChange={ changeGasto }
                                        options={ gastosAll }
                                        name={`pproducto-nuevo`}
                                        mode="tags"
                                        maxCount={1}
                                        value={gasto}
                                    />                               
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        name={`pprecio-nuevo`}
                                    />
                                </td>
                                <td>
                                    <button className='btn btn-primary' onClick={() => saveProduct(-1)}>+ Producto</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>
            <Modal className='ctri-modal' title="Etiquetas" width={500} open={isModalLabelOpen} cancelText="Cerrar" onOk={handleEtiCancel} onCancel={handleEtiCancel}>
                <CRow>
                    <Col span={24} className='text-end'>
                        <Form name="flabel" form={flabel}  layout='vertical'>
                            <CRow>
                                <Col span={12}>
                                    <Form.Item label="Nombre" name="nombre"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: Nuevos' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Color" name="color"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            <ColorPicker value={color} onChange={handleColorChange} />
                                            <Input value={color} readOnly />
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Button type="primary" onClick={onSaveLabel}>Guardar</Button>
                                </Col>
                            </CRow>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <DataTable
                            noDataComponent={"No se han encontrado resultados"}
                            columns={ columns }
                            data={dataLabels}
                            pagination 
                            paginationServer
                            responsive             
                        />
                    </Col>
                </CRow>
            </Modal>
            <Modal className='ctri-modal' closable={true} 
                maskClosable={false} footer={
                !isEdit && [ // Solo muestra el footer si no está en modo edición
                    <Button key="cancel" onClick={handleCancel}>
                    Cancelar
                    </Button>,
                    <Button key="ok" type="primary" onClick={handleOk}>
                    Guardar
                    </Button>,
                ]
                } title={ titleModal } width={700} open={isModalOpen} okText="Guardar" cancelText="Cancelar" onOk={handleOk} onCancel={handleCancel}>
                <Form name="basic" form={form_create} layout='vertical' onChange={ searchPlate }>
                    <CRow className='mb-4'>
                        <Col span={24} className='mb-2'>
                            {dataLabels.filter(item => labels.includes(item.color)).map((item, index) => (
                                <span key={`label-${index}`} style={{ background: item.color, color: '#FFFFFF', padding: '1px 16px'}} className='me-1'>
                                    { item.name }
                                </span>         
                            ))}  
                        </Col>                        
                        <Col span={16}>                        
                            <CRow> 
                                <Col md={24} xs={24}>
                                {
                                    isEdit ?
                                    <Form.Item label="Vehiculo" name="placa">
                                         <Input type='text' placeholder='Ej: Mercedes-Benz' disabled/>
                                    </Form.Item> :
                                    <Form.Item label="Vehiculo" name="placa"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido!',
                                        }
                                    ]}>
                                    <Select
                                        showSearch
                                        placeholder="Seleccione un vehiculo"
                                        optionFilterProp="label"
                                        options={ vehicles }
                                        mode="tags"
                                        onChange={onSelectCar}
                                        maxCount={1}
                                    />
                                </Form.Item> 
                                }
                                </Col>   
                                <Col md={12} xs={24}>
                                    <Form.Item label="Marca" name="marca"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: Mercedes-Benz' disabled/>
                                    </Form.Item>
                                </Col> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Modelo" name="modelo"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: 2022' disabled/>
                                    </Form.Item>
                                </Col> 
                                <Col md={12} xs={24}>
                                    <Form.Item label="Linea" name="linea"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido!',
                                            }
                                        ]}>
                                        <Input type='text' placeholder='Ej: SLK 200' disabled/>
                                    </Form.Item>
                                </Col>                                                                                            
                            </CRow>                                             
                        </Col>                    
                        <Col span={8}>
                            <CRow>
                                <Col span={24}>
                                    <Form.Item label="Miembros" name="miembros">
                                        <Select mode="multiple"
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={ saveMember }
                                            options={users}>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Etiquetas" name="etiquetas">
                                        <Select
                                            mode="multiple"
                                            tagRender={tagRender}
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={ saveLabel }
                                            options={dataLabels}
                                        />
                                    </Form.Item>
                                </Col>                                
                                {
                                    isEdit ? 
                                    <Col span={24}>
                                        <div className='form-group'>
                                            <label>Gastos</label>
                                            <p className='mb-0'><a style={{ color: '#ff8600', textDecoration: 'underline', fontWeight: 'bold' }} onClick={ showModalGastos }>{ gastos !== '' ? gastos : 'COP 0' }</a></p>
                                        </div>
                                        {/* <div className='form-group'>
                                            <Tareas task={ taskSelected } />
                                        </div> */}
                                    </Col> : null
                                }  
                            </CRow>
                        </Col>                        
                    </CRow>
                    <CRow>
                        <Col md={12} xs={12}>
                            <Form.Item label="Costo" name="costo">
                                <Input type='text' placeholder='Ej: 50000000' disabled/>
                            </Form.Item>
                        </Col>  
                        <Col md={12} xs={12}>
                            <Form.Item label="Propietario" name="propietario">
                                <Input type='text' placeholder='-' disabled/>
                            </Form.Item>
                        </Col>  
                    </CRow>
                    <CRow>
                        <Col md={6} xs={6}>
                            <Form.Item label="Precio mínimo" name="precio_minimo">
                                <Input  disabled />
                            </Form.Item>
                        </Col>
                        <Col md={6} xs={6}>
                            <Form.Item label="% comisión" name="porc_comision">
                                <Input  disabled/>
                            </Form.Item>
                        </Col>
                        <Col md={6} xs={6}>
                            <Form.Item label="Fecha SOAT" name="fecha_soat">
                                <Input  disabled/>
                            </Form.Item>
                        </Col>
                        <Col md={6} xs={6}>
                            <Form.Item label="Fecha RTM" name="fecha_rtm">
                                <Input  disabled/>
                            </Form.Item>
                        </Col>
                    </CRow>
                    <CRow className='mb-2'>                        
                        <Col md={24} xs={24}>
                            <Form.Item label="Descripcion" name="descripcion">
                                <TextArea rows={4} disabled={openDesc} onBlur={() => saveField('descripcion', 'La descripción') }/>
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24} className='text-end'>
                            <button onClick={ () => setOpenDesc(!openDesc) } className='btn btn-info' type='button'>Editar</button>
                        </Col>
                    </CRow>
                    <CRow>
                        <Col>
                            
                            { isEdit ? <><hr /> <p className='lbl-modal mb-2'><strong>Documentación de ventas</strong></p> </> : null }                            
                            {
                                ifTasks ? 
                                <Flex gap="small" className='mb-3' vertical>
                                    <Progress percent={porcTasks} status="active" />
                                </Flex> : null
                            }      
                            {
                                isEdit ? 
                                    <Collapse accordion>
                                        <Panel header="Checklist" key="1">
                                            <Form.List name="tasks">
                                            {(fields, { add, remove }) => (
                                                <>
                                                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                                    <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                                        <Form.Item
                                                            name={[name, 'completed']}
                                                            valuePropName="checked"
                                                            className='me-3'
                                                            fieldKey={[fieldKey, 'completed']}
                                                        >
                                                            <Checkbox onChange={(e) => handleCheckboxChange(e.target.checked, index)}></Checkbox>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            className='w-100'
                                                            name={[name, 'task']}
                                                            fieldKey={[fieldKey, 'task']}
                                                            style={{ textDecoration: isTaskCompleted(index) ? 'line-through' : 'none' }}
                                                        >
                                                            <Input placeholder="Nueva tarea" 
                                                                style={{ textDecoration: isTaskCompleted(index) ? 'line-through' : 'none' }} />
                                                        </Form.Item>
                                                        <MinusCircleOutlined className='ms-3' onClick={() => remove(name)} />
                                                    </div>
                                                ))}

                                                <Form.Item>
                                                    <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    block
                                                    icon={<PlusOutlined />}
                                                    style={{ marginTop: '10px' }}
                                                    >
                                                    Nueva tarea
                                                    </Button>
                                                </Form.Item>
                                                </>
                                            )}
                                            </Form.List>
                                        </Panel>
                                    </Collapse>:null
                                } 
                        </Col>
                    </CRow>  
                </Form>
                { isEdit ?
                    <CRow>
                        <Col span={24} className='mb-2'>
                            <p className='lbl-modal mb-2'><strong>Notas</strong></p>
                            <textarea className='form-control' name='notas_add' placeholder='Notas'></textarea>
                            <button style={{ float: 'right', marginTop: '4px'}} type='button' className='btn btn-sm btn-primary' onClick={ addNote }>Agregar</button>
                        </Col>
                        <Col span={24}>                           
                            {notes.length > 0 ? notes.map((item, index) => (
                                <dl key={`note-${index}`} className='mb-0 border-bottom'>
                                    <dt className='mb-1'><b>{item.names}: </b> <span style={{ float: 'right', fontSize: '12px'}}>{ Moment( item.created_at).format("DD/MM/YYYY h:mm:ss a")}</span></dt>
                                    <dd><div className="desc1" dangerouslySetInnerHTML={{ __html: item.note.replace(/\n/g, "<br />") }} /></dd>
                                </dl>         
                            )) : null}  
                        </Col>
                        <Col span={24} className='my-2'>
                            <hr />
                            {/* Archivos */}
                            <label>Si desea agregar archivos adicionales puede hacerlo aqui, <strong>OJO: Estos archivos quedan asociados a la placa del vehiculo.</strong></label>
                            <Archivos ref={archivosRef} car_id={ taskFull.id } placa={ taskFull.plate } />
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Seleccione un archivo</Button>
                            </Upload>
                            <Button
                                type="primary"
                                onClick={handleUpload}
                                disabled={fileList.length === 0}
                                loading={uploading}
                                style={{
                                    marginTop: 16,
                                }}
                            >
                                {uploading ? 'Subiendo' : 'Iniciar subida'}
                            </Button>
                            <hr />
                        </Col>
                    </CRow> : null
                }
                <CRow>
                    <BitacoraAccordion bitacora={bitacora} />
                </CRow>
                <CRow>
                    <Col span={24} className='text-end mt-2'>
                    {
                        isEdit ? 
                            taskSelected.column && taskSelected.column !== 'column-11' ?
                            <button onClick={ () => archivar(11) } className='btn btn-sm btn-primary' type='button'>¿Archivar?</button>:
                            <button onClick={ () => archivar(1) } className='btn btn-sm btn-primary' type='button'>¿Restaurar?</button> 
                            : null
                    }                                    
                </Col>
                </CRow>
            </Modal>            
            <div className='ctripipe'>
                <CRow className='mb-2 pt-2 btripipe'>
                    <Col span={7}></Col>
                    <Col span={10}>
                        <div className='text-start'>
                            <input
                                type="text"
                                placeholder="Buscar..."
                                onChange={search}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                name="inputSearch"
                            />
                            {showResults && (
                                <div className="results-dropdown">
                                <ul className='results-list'>
                                    {results.map((item, index) => (
                                    <li key={index} onClick={() => showModal(item)} className="result-item">
                                        <div className="result-header">
                                            <span className="brand">{ item.title } { item.placa ? ' - '+item.placa : ''}</span>
                                        </div>
                                        <div className="result-price">
                                            { moneyFormat(item.cost) }
                                        </div>
                                            <div className="result-details">
                                            <p className="notes">
                                                <strong>NOTE:</strong> { item.nota }
                                            </p>
                                            { item.cant_nota > 1 ? <p className="notes"> Tiene mas notas (<strong>{ item.cant_nota}</strong>) </p> : '' }
                                            <p className="contact">
                                                { item.name_contact} { ' - '+item.columna}
                                            </p>
                                        </div>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                            )}
                        </div>
                    </Col>
                    <Col span={7} className='text-end'>
                        <button className='btn btn-info' onClick={ showModalEtiquetas }>Etiquetas</button>
                    </Col>
                </CRow>
                <div className='ctripipe2'>
                    <CRow className='ctrirow'>
                        <Col span={24}>
                            <DragDropContext onDragEnd={onDragEnd}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {   
                                    data && data.columns && data.columnOrder.map((columnId) => {
                                    const column = data.columns[columnId];
                                    const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
                                    const totalCosto = tasks.reduce((acumulador, objeto) => acumulador + objeto.cost, 0);

                                    return (
                                        column ? 
                                        <Droppable droppableId={column.id} key={column.id}>
                                            {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className={'column '+column.id}                                    
                                            >
                                                <div className="cabecera">
                                                    <div className='d-flex justify-content-between'>
                                                        <h3>{column.title}</h3>                                        
                                                        <button className='btn btn_add' onClick={() => addTask(column)}>
                                                            +
                                                        </button>
                                                    </div>
                                                    <p className='desp'>{ moneyFormat(totalCosto) } - { tasks.length } deals</p>
                                                </div>
                                                <div className='cuerpo'>
                                                    {tasks && tasks.map((task, index) => (
                                                        task.id ? 
                                                        <Draggable key={task.id} draggableId={task.id} index={index} >
                                                            {(provided) => (
                                                            <div onClick={() => showModal(task) }
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps} className='task'>
                                                                <div>
                                                                    <h6 className='mb-0'>{ task.content } - <span className='text-uppercase'>{ task.placa }</span></h6> 
                                                                    <div className='d-flex'>
                                                                        {
                                                                            task.labels.map((item, index) => (
                                                                                <span key={`label-${index}`} 
                                                                                style={{ 
                                                                                    background: item, 
                                                                                    color: '#FFFFFF', 
                                                                                    padding: '1px 16px', 
                                                                                    width: '35px', 
                                                                                    height: '5px',
                                                                                    borderRadius: '2px',
                                                                                    margin: '3px 0px',
                                                                                    display: 'block'
                                                                                }} className='me-1'> </span>         
                                                                            ))
                                                                        } 
                                                                    </div>
                                                                    {/* <p className='contact'>{ task.contact ? task.contact : '-' }</p>  */}
                                                                    <p className='mb-0'>{ moneyFormat(task.cost ? task.cost : 0) }</p>
                                                                    <p className='mb-0' style={{ color: '#ff8600' }}>Gastos: { task.gastos !== '' ? moneyFormat(task.gastos) : 'COP 0' }</p>
                                                                    <div className='d-flex flex-row-reverse'>
                                                                    {
                                                                        task.iniciales ? task.iniciales.split(',').map((item, index) => (
                                                                            <p key={index} className='mb-0' style={{
                                                                                background: task.color_iniciales.split(',')[index]?.trim() || 'gray', // Asigna el color correspondiente o usa un valor por defecto
                                                                                color: '#FFFFFF',
                                                                                borderRadius: '50%',
                                                                                padding: '6px',
                                                                                fontWeight: 'bold',
                                                                                marginLeft: '4px'
                                                                            }}>
                                                                                {item.trim()}
                                                                            </p>
                                                                        ))  : null  
                                                                    }
                                                                </div>
                                            
                                                                </div>
                                                            </div>
                                                            )}
                                                        </Draggable> : null
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            </div>
                                            )}
                                        </Droppable> : null
                                        )
                                    })
                                }
                            </div>
                        </DragDropContext>
                        </Col>
                    </CRow>  
                </div>
            </div>          
        </div>
    )
}

export default Index;
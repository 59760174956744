import React, {useEffect} from 'react'
import { Form, Col, Input, message, Slider, Button } from 'antd';
import { CRow } from '@coreui/react';
import ActasService from 'src/services/ActasService';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import TramitesService from 'src/services/TramitesService';

const CreateTramite = () => {
    const [form] = Form.useForm();
    const [disabledButton, setDisabledButton] = React.useState(false)
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [estado, setEstado] = React.useState(0);
    const [vehiculoId, setVehiculoID] = React.useState(0);
    const marks = {
        0: 'Recolección de documentos',
        1: 'Enviado a traspaso',
        2: 'Radicado',
        3: 'Aprobado/Rechazado',
        4: 'Listado para entrega'
    };


    const onFinish = (values) =>{
        setDisabledButton(true)
        let _data = {
            tramite_estado: estado,
            vehiculoId: vehiculoId,
            created_by: authUser().id,
        }

        TramitesService.save(_data).then(resp => {
            message.success("Tramite creado exitosamente");
            setTimeout(() => {
                navigate("/estado-de-tramites")
            }, 2000);
        })
    }

    const searchPlate = (event) =>{
        if(event.target.id === 'basic_placa' && event.target.value.length === 6){
            ActasService.getByPlate(event.target.value).then(resp =>{
                let _data = resp.data
                if(_data.length > 0){
                    _data = _data[0]
                    setVehiculoID(_data.id);
                    let _fields = {
                        marca: _data.make,
                        modelo: _data.model,
                        linea: _data.version
                    }
                    form.setFieldsValue(_fields)
                }
            })
        }
    }

    const saveEstado = (event) =>{
        let _estado = marks[event]
        setEstado(_estado)
    }

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
    }, []);

    return(
        <>
            <CRow>
                <Col span={24} className='text-center my-4'>
                    <h3>Crear estado de trámite</h3>
                </Col>
            </CRow>
            <Form name="basic" form={form} onFinish={onFinish} layout='vertical' onChange={ searchPlate }>
                <CRow>
                    <Col md={12} xs={24}>
                        <Form.Item label="Placa" name="placa"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: RNR549' />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Marca" name="marca"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Mercedes-Benz' disabled/>
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Modelo" name="modelo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Clase SLK' disabled/>
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Linea" name="linea"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: SLK 200' disabled/>
                        </Form.Item>
                    </Col> 
                </CRow>
                <hr />
                <CRow>
                    <Col md={24} xs={24}>
                        <div className='slider-tramites w-80 mx-auto'>
                            <Slider defaultValue={0} marks={marks} min={0} max={4} step={1} controlSize={40} dotActiveBorderColor={"#ff6522"} onChange={saveEstado} />
                        </div>
                    </Col>
                </CRow>
                <hr />
                <CRow className='my-4'>
                    <Col span={24} xs={24} md={24} className='text-center'>
                        <Button type='primary'  htmlType="submit" disabled={disabledButton}>Crear</Button>
                    </Col>
                </CRow>
            </Form>
        </>    
    )
}

export default CreateTramite;

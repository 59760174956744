import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { useParams } from "react-router-dom";
import { CRow, CCol } from '@coreui/react';
import VehiculosService from "src/services/VehiculosService";
import TripipeService from "src/services/TripipeService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CardF } from './CardF.js'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import { message, Switch, Collapse, Input, Card, Checkbox, Slider, Select, Button, Upload, Table, Space, Modal, DatePicker } from 'antd';
import { FormOutlined, UploadOutlined } from '@ant-design/icons';
import { Link  } from "react-router-dom";
import Archivos from './Archivos.js';
import DriverService from 'src/services/Driver.js';
import Moment from 'moment'
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import $ from "jquery";
import dayjs from 'dayjs';
import { useAuthUser } from 'react-auth-kit';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Panel } = Collapse;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';

const moneyFormat = (value) => new Intl.NumberFormat().format(value);
const marks = {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
};
const estados = [
    {value: 'Bueno', label: 'Bueno'},
    {value: 'Muy Bueno', label: 'Muy Bueno'},
    {value: 'Excelente', label: 'Excelente'}
];
const multasOptions = [
    {value: 'Si', label: 'Si'},
    {value: 'No', label: 'No'},
]
const duenos = [
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4 o mas', label: '4 o mas'},
    {value: 'No se sabe', label: 'No se sabe'},
];
const columnsTraslados = [
    {
        title: 'Fecha',
        dataIndex: 'created_at',
        key: 'created_at', //
        render: (text) => Moment(text).format("DD/MM/YYYY h:mm:ss a"),
    },
    {
        title: 'Kilometraje',
        dataIndex: 'km',
        key: 'km',
        render: (text) => moneyFormat(text)+" KM",
    },
    {
        title: 'Dir. Recepción',
        dataIndex: 'direccion_recepcion',
        key: 'direccion_recepcion',
    },
    {
        title: 'Dir. Entrega',
        dataIndex: 'direccion_entrega',
        key: 'direccion_entrega',
    },
    {
        title: 'Estado',
        dataIndex: 'estado',
        key: 'estado',
        render: (text) => <span className={ text === 'Completado' ? "badge rounded-pill text-bg-info" : "badge rounded-pill text-bg-danger"}>{ text }</span>
    },
    {
        title: 'Ver',
        key: 'action',
        render: (_, record) => (
          <>
            <Space size="middle">
              <a href={"/conductor/consultar/"+record.id} target='_blank'>Ver</a>
            </Space>
          </>          
        ),
    }
]
const Edit = () =>{
    const [car, setCar] = useState({});
    var { id } = useParams();
    const [photos, setPhotos] = useState([]);
    const [isTriplo, setIsTriplo] = useState(false);
    const [vendido, setVendido] = useState(false);
    const [separate, setSeparate] = useState(false);
    const [publicado, setPublicado] = useState(false);
    const [images, setImages] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [traslados, setTraslados] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [carsList, setCarsList] = useState([]);
    const [carsMercadoLibre, setCarsMercadoLibre] = useState([]);
    const fileInputRef = useRef(null);
    const [showLot, setShowLot] = useState(false);
    const [selectedCar, setSelectedCar] = useState(null);
    const [isModalSyncOpen, setIsModalSyncOpen] = useState(false);
    const [carId, setCarId] = useState(0)
    const [clients, setClients] = useState([]);
    const archivosRef = useRef();
    const authUser = useAuthUser();
    const props = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
    };

    const handleUpload = async () => {

        setUploading(true);
        let names = [];
        const uploadPromises = fileList.map((element, index) => {
            const data = new FormData();
            data.append('file', element);
            names[index] = element.name;
            return VehiculosService.uploadFile(data, `archivos_${car.plate}`);
        });

        // Espera a que todas las imágenes se suban
        const responses = await Promise.all(uploadPromises);
        
        // Extrae las URLs de las imágenes
        const images = responses.map(response => response.uri);
        
        images.forEach((element, index) => {
            let _data = {
                name: names[index],
                url: element,
                user_id: authUser()?.id              
            }
            VehiculosService.setArchivos(_data, car.id).then(resp =>{
                setFileList([]);
                message.success('Archivo(s) subidos correctamente.');
                setUploading(false);
                if (archivosRef.current) {
                    archivosRef.current.reload();
                }
            })
        });
    };

    const getStatus = (_car) => {
        const { status_im, published, link_ml } = _car;
    
        if (status_im === 'inactive' && published === 0) {
            return <span className="badge text-bg-danger">No</span>;
        }
    
        if (link_ml) {
            const mercadoLibreBadge = (
                <span className="badge text-bg-warning">
                    <a 
                        style={{ color: 'white', textDecoration: 'none' }} 
                        href={link_ml} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        Mercado libre
                    </a>
                </span>
            );
    
            if (published === 1) {
                return (
                    <>
                        {mercadoLibreBadge}&nbsp;&nbsp;
                        <span className="badge text-bg-success">Si</span>
                    </>
                );
            }
    
            return mercadoLibreBadge;
        }
    
        return null; // Por si no cumple ninguna de las condiciones
    };

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setPhotos((prevCards) => {
            const newPhotosOrder = update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            });
            
            const urls = newPhotosOrder.map(picture => picture.url).join(',');
            const data = {
                pictures: urls, 
            };

            VehiculosService.saveImagens(data, id).then(response =>{
                console.log('Updated photo order:', response);
            }).catch(error => {
                console.error('Error updating photo order:', error);
            });            
    
            return newPhotosOrder;
        });
    }, [])

    const removeCard = (url) => {
        ///Revisar aqui
        setPhotos((prevPhotos) => {
            const updatedPhotos = prevPhotos.filter(photo => photo.url !== url);
            let _name = url.split("/");
            let data = {
                folder_name: `auto_${id}`,
                file_name: _name[_name.length - 1],
            };
            VehiculosService.deletePhoto(data).then(resp => {
                // Update photos in the database
                const urls = updatedPhotos.map(picture => picture.url).join(',');
                let _data = {
                    pictures: urls
                };
                VehiculosService.saveImagens(_data, id).then(response => {
                    //
                });
            });
    
            return updatedPhotos;
        });
    };

    const renderCard = useCallback((card, index) => {
        return (
          <CardF
            key={card.id}
            index={index}
            id={card.id}
            text={card.url}
            moveCard={moveCard}
            removeCard={removeCard}
          />
        )
    }, [])

    const getTraslados = (plate) =>{
        DriverService.getByPlaca(plate).then(resp => {
            setTraslados(resp.data)
        })
    }

    const changeSeparar = (condicion) =>{
        let data = {
            id: id,
            value: condicion ? 1 : 0
        }
        setSeparate(condicion)
        VehiculosService.changeSeparate(data).then(resp =>{
            message.success("Actualizado con exito")
        }).catch(error => console.log(error))
    }

    const changeVendido = (condicion) =>{
        let data = {
            id: id,
            value: condicion ? 1 : 0
        }
        setVendido(condicion)
        VehiculosService.changeSale(data).then(resp =>{
            message.success("Actualizado con exito")
        }).catch(error => console.log(error))
    }

    const changeIsTriplo = (condicion) =>{
        let data = {
            id: id,
            value: condicion ? 1 : 0
        }
        setIsTriplo(condicion)
        VehiculosService.changeIsTriplo(data).then(resp =>{
            message.success("Actualizado con exito")
        }).catch(error => console.log(error))
    }

    const changePublicado = (condicion) =>{
        let data = {
            id: id,
            value: condicion ? 1 : 0
        }
        setPublicado(condicion)
        VehiculosService.changePublicado(data).then(resp =>{
            message.success("Actualizado con exito")
        }).catch(error => console.log(error))
    }

    const getCar = async () =>{
        try {
            setCarId(id)
            const resp = await VehiculosService.get(id);
            const { separate, is_sale, is_triplo, pictures, plate , published } = resp.response;
    
            // Actualiza los estados principales
            setCar(resp.response);
            setSeparate(separate);
            setPublicado(published);
            setVendido(is_sale);
            setIsTriplo(is_triplo);
            setImages(pictures);
            
            // Procesa las fotos y actualiza su estado
            let photosArray = [];
            if(pictures)
                photosArray = pictures.split(',').map((url, index) => ({
                    id: index + 1,
                    url
                }));
            setPhotos(photosArray);
            // Llama a getTraslados si la placa está disponible
            if (plate) {                
                getTraslados(plate);
            }
            getClients();
        } catch (error) {
            console.error("Error fetching car data:", error);
        }
    }

    const getClients = async () =>{
        const resp = await TripipeService.getClientsVeh();
        const { users } = resp;
        
        const mappedData = users.map(item => ({
            value: `${item.identification ? moneyFormat(item.identification) : ''} - ${item.label}`,
            label: `${item.identification ? moneyFormat(item.identification) : ''} - ${item.label}`
        }));

        setClients(mappedData)
    }
    const handleChange = (e) => {
        setCar({
            ...car,
            [e.target.name]: e.target.value
        });
    };
    const guardarEstado = (value) =>{
        let data = {
            id: id,
            field: 'estado_general',
            value: value,
            type: 'string'
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    }
    const guardarMultas = (value) =>{
        let data = {
            id: id,
            field: 'valor_multa',
            value: value,
            type: 'string'
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    }

    const guardarDuenos = (value) =>{
        let data = {
            id: id,
            field: 'nro_duenos',
            value: value,
            type: 'string'
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    }

    const guardarPropietario = (value) =>{
        let data = {
            id: id,
            field: 'propietario',
            value: value,
            type: 'string'
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    }
    const changeRuedasTra = (value) =>{
        let data = {
            id: id,
            field: 'estado_llanta_tra',
            value: value,
            type: ''
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    }
    const changeRuedasDel = (value) =>{
        let data = {
            id: id,
            field: 'estado_llanta_del',
            value: value,
            type: ''
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    }
    //fecha_rtm
    const handleDateChange3 = (date, dateString) => {
        let data = {
            id: id,
            field: "fecha_rtm",
            value: dateString,
            type: "string"
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    };
    const handleDateChange2 = (date, dateString) => {
        let data = {
            id: id,
            field: "fecha_soat",
            value: dateString,
            type: "string"
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    };
    const handleDateChange1 = (date, dateString) => {
        let data = {
            id: id,
            field: "fecha_matricula",
            value: dateString,
            type: "string"
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    };
    const guardarElemento = (e) =>{
        let _type = (e.target.value == 'on' || e.target.value == 'off') ? '' : 'string';
        let _value = (e.target.value == 'on' || e.target.value == 'off') ? e.target.checked : e.target.value;        
        let data = {
            id: id,
            field: e.target.name,
            value: _value,
            type: _type
        }
        
        VehiculosService.updateField(data).then(resp =>{
            message.success('Actualizado con exito');
            getCar()
        })
    }

    const onChangeDate = (date, dateString) => {
        console.log(date, dateString);
    };

    const handleImageUpload = (event) => {
        setSelectedFiles(event.target.files);
    };

    const actualizarImagenes = async () =>{
        $('body,html').stop(true,true).animate({				
            scrollTop: $('body').offset().top
        },100);
        setShowLot(true)
        let pictureUris = images;
        
        if (selectedFiles.length === 0) {
            setShowLot(false)
            message.error("No existen fotos seleccionadas")
            return;
        }

        try {
            const uploadPromises = Array.from(selectedFiles).map((file, index) => {
                const formData = new FormData();
                formData.append('file', file); // Asegúrate de que el formato sea correcto
                return VehiculosService.uploadPhoto(formData, `auto_${id}`);
            });
        
            const responses = await Promise.all(uploadPromises);
            responses.forEach((response) => {
                pictureUris += ","+response.uri; // Concatenar las URIs separadas por comas
            });
            
            if (pictureUris.endsWith(",")) {
                pictureUris = pictureUris.slice(0, -1);
            }
            
            let _data = {
                pictures: pictureUris
            }
            await VehiculosService.saveImagens(_data, id);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            let data = {
                id: id,
                field: 'published',
                value: true,
                type: ''
            }
            
            VehiculosService.updateField(data).then(resp =>{
                message.success('Actualizado con exito');
                getCar()
            })
            getCar();
            setShowLot(false)

        } catch (error) {
            setShowLot(false)
            console.error('Error al subir las imágenes:', error);
        }
    }

    const guardarCarroML = (value) =>{
        setSelectedCar(value)
    }
    const sincronizar = () =>{
        let data = {
            marca: car.make,
            modelo: car.model,
            anho: car.year
        }
        VehiculosService.updateNewCars(data).then(resp =>{
            const newArray = [];
            setCarsMercadoLibre(resp)
            resp.forEach((item) => {
                newArray.push({ label: item.title, value: item.id });
            });
            setCarsList(newArray);
            setIsModalSyncOpen(true);
        })
    }

    const sincronizarML = () =>{
        console.log(car)
        VehiculosService.getCarByML(car.id_mercado_libre).then(resp =>{
            console.log(resp)
            let selectedCarData = resp[0]
            // Extraer datos necesarios
            const { price, id, permalink, attributes } = selectedCarData;
        
            // Buscar atributos específicos
            const make = attributes.find(attr => attr.id === "BRAND")?.value_name || "Unknown";
            const kilometers = attributes.find(attr => attr.id === "KILOMETERS")?.value_struct?.number || null;
        
            // Crear el objeto de datos
            const carData = {
                make,
                price,
                link_ml: permalink,
                kilometers,
                id_mercado_libre: id,
                id: carId, // Duplicado para mantener consistencia con el código original
            };
        
            // Actualizar el vehículo en el servicio
            VehiculosService.updateCarML(carData).then(() => {
                setCarsList([])
                setCarsMercadoLibre([])
                getCar();
                setIsModalSyncOpen(false);
                window.location.reload();
            }).catch(error => {
                console.error("Error updating car:", error);
            });
        })
    }

    const handleSyncOk = () => {
        // Filtrar el carro seleccionado
        const selectedCarData = carsMercadoLibre.find(car => car.id === selectedCar);
    
        if (!selectedCarData) {
            console.error("Car not found!");
            return;
        }
        
        // Extraer datos necesarios
        const { price, id, permalink, attributes } = selectedCarData;
    
        // Buscar atributos específicos
        const make = attributes.find(attr => attr.id === "BRAND")?.value_name || "Unknown";
        const kilometers = attributes.find(attr => attr.id === "KILOMETERS")?.value_struct?.number || null;
    
        // Crear el objeto de datos
        const carData = {
            make,
            price,
            link_ml: permalink,
            kilometers,
            id_mercado_libre: id,
            id: carId, // Duplicado para mantener consistencia con el código original
        };
    
        // Actualizar el vehículo en el servicio
        VehiculosService.updateCarML(carData).then(() => {
            setCarsList([])
            setCarsMercadoLibre([])
            getCar();
            setIsModalSyncOpen(false);
            window.location.reload();
        }).catch(error => {
            console.error("Error updating car:", error);
        });
    };
    
    const handleSyncCancel = () => {
        setIsModalSyncOpen(false);
    };

    useEffect(() => {
        getCar();
    }, [])

    return(
        <>
            <Modal title="Seleccione el vehículo" open={isModalSyncOpen} onOk={handleSyncOk} onCancel={handleSyncCancel}>
                <CRow>
                    <CCol xs={12}>
                        <div className='form-group'>
                            <label>Vehiculo Mercado Libre</label>
                            <Select options={ carsList } id="car_sel_ml" style={{ width: '100%'}} showSearch placeholder="Seleccione" onChange={ guardarCarroML }></Select>
                        </div>
                    </CCol>
                </CRow>
            </Modal>
            <CRow>
                <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                    <Lottie animationData={gifJson} loop={true} className="loadLottie" /> 
                </div> 
                <CCol xs={12}>
                    <CRow>
                        <CCol xs={12} sm={12} md={5}>
                            <div>
                                <h4>
                                    { car.make+" "+car.model+" "+car.year } { car.plate ? ' - '+car.plate : '' }
                                </h4>
                            </div>
                        </CCol>
                        <CCol xs={12} sm={12} md={7}>
                            <div className='d-flex d-flex2 align-items-center justify-content-end'>
                                <Switch checked={isTriplo} checkedChildren="Triplo" unCheckedChildren="No Triplo" size='medium' className='me-2' onClick={ changeIsTriplo  } />
                                <Switch checked={separate} checkedChildren="Separado" unCheckedChildren="Sin separar" size='medium' className='me-2' onClick={ changeSeparar }/>
                                <Switch checked={vendido} checkedChildren="Vendido" unCheckedChildren="No vendido" size='medium' className='me-2' onClick={ changeVendido } />         
                                <Switch checked={publicado} checkedChildren="Publicado" unCheckedChildren="No publicado" size='medium' className='me-2' onClick={ changePublicado } />         
                                { getStatus(car) } 
                                <Button style={{ marginLeft: '8px'}} className='ms-2' type="primary" size={"small"} onClick={ sincronizar }>
                                    Sincronizar
                                </Button>        
                            </div>
                        </CCol>
                    </CRow>
                    <hr />
                    <form>
                        <CRow className='mb-4'>
                            <CCol xs={12}>
                                <Card style={{ width: '100%' }}>
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Marca</label>
                                                <input name="make" defaultValue={car.make} placeholder="Ej. Toyota" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Modelo</label>
                                                <input name="year" defaultValue={car.year} placeholder="Ej. 2020" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Línea</label>
                                                <input name="model" defaultValue={car.model} placeholder="Ej. Corolla" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Versión</label>
                                                <input name="version" defaultValue={car.version} placeholder="Ej. LT" className='form-control' onBlur={ guardarElemento }/>
                                            </div>                                                                               
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Tipo de vehículo</label>
                                                <input name="vehicle_type" defaultValue={car.vehicle_type} className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Tipo de carrocería</label>
                                                <input name="vehicle_body_type" defaultValue={car.vehicle_body_type} className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Transmisión</label>
                                                <input name="transmission" defaultValue={car.transmission} className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Tipo de combustible</label>
                                                <input name="fuel_type" defaultValue={car.fuel_type} className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Color</label>
                                                <input name="color" defaultValue={car.color} placeholder="Ej. Negro" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Kilometraje</label>
                                                <input name="kilometers" defaultValue={car.kilometers}  className='form-control'  onBlur={ guardarElemento}/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Nro de Chasis</label>
                                                <input name="chasis" defaultValue={car.chasis} placeholder="Chasis" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Nro de Motor</label>
                                                <input name="motor" defaultValue={car.motor} placeholder="Motor" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                    </CRow> 
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Cilindraje</label>
                                                <input name="cilindraje" defaultValue={car.cilindraje} placeholder="" className='form-control' onBlur={ guardarElemento }/>
                                            </div> 
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Fasecolda</label>
                                                <input name="fasecolda" defaultValue={car.fasecolda} placeholder="" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Clase de vehículo</label>
                                                <input name="clase" defaultValue={car.clase} placeholder="" className='form-control' onBlur={ guardarElemento }/>
                                            </div> 
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group'>
                                                <label>Tipo de servicio</label>
                                                <input name="servicio" defaultValue={car.servicio} placeholder="" className='form-control' onBlur={ guardarElemento }/>
                                            </div>  
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Valor impuestos</label>
                                                <input name="valor_impuesto" defaultValue={car.valor_impuesto} placeholder="" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                            {
                                                car.valor_impuesto && car.valor_impuesto.length > 0 ? 
                                                <div className='form-group '>
                                                    <label>Impuestos</label>
                                                    <textarea name="impuestos"  placeholder="" className='form-control' onBlur={ guardarElemento }>{car.impuestos}</textarea>
                                                </div> : null
                                            }
                                            
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Valor SOAT</label>
                                                <input name="valor_soat" defaultValue={car.valor_soat} placeholder="" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                            {
                                                car.valor_soat && car.valor_soat.length > 0 ? 
                                                <div className='form-group '>
                                                    <label>SOAT</label>
                                                    <textarea name="soat"  placeholder="" className='form-control' onBlur={ guardarElemento }>{car.soat}</textarea>
                                                </div> : null
                                            }
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Multas</label>
                                                <Select options={ multasOptions } value={ car.valor_multa } name="estado_general" style={{ width: '100%'}} showSearch placeholder="Seleccione" onChange={ guardarMultas }></Select>
                                            </div>
                                            {
                                                car.valor_multa && car.valor_multa == 'Si' ? 
                                                <div className='form-group '>
                                                    <label>Observación multas</label>
                                                    <textarea name="multas"  placeholder="" className='form-control' onBlur={ guardarElemento }>{car.soat}</textarea>
                                                </div> : null
                                            }
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Autoridad de tránsito</label>
                                                <input name="lugar_matricula" defaultValue={car.lugar_matricula} placeholder="" className='form-control' onBlur={ guardarElemento }/>
                                            </div> 
                                        </CCol>                                        
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>                                               
                                            <div className='form-group '>
                                                <label>¿Cuántas llaves tiene?</label>
                                                <input name="segunda_llave" defaultValue={car.segunda_llave} placeholder="Ej. 3" className='form-control' onBlur={ guardarElemento }/>
                                            </div> 
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Avaluo</label>
                                                <input name="avaluo" defaultValue={car.avaluo} placeholder="" className='form-control' onBlur={ guardarElemento }/>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Fecha matricula</label>
                                                {/* <input name="fecha_matricula" type='date' defaultValue={car.fecha_matricula} placeholder="" className='form-control' onBlur={ guardarElemento }/> */}
                                                <DatePicker name="fecha_matricula" className='form-control' value={car.fecha_matricula ? dayjs(car.fecha_matricula, dateFormat) : null} format={dateFormat} onChange={ handleDateChange1 } />
                                            </div> 
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Fecha SOAT</label>
                                                <DatePicker name="fecha_soat" className='form-control' value={car.fecha_soat ? dayjs(car.fecha_soat, dateFormat) : null} format={dateFormat} onChange={ handleDateChange2 } />
                                            </div>
                                        </CCol>                                        
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Fecha RTM</label>
                                                <DatePicker name="fecha_rtm" className='form-control' 
                                                value={car.fecha_rtm ? dayjs(car.fecha_rtm, dateFormat) : null} format={dateFormat} onChange={ handleDateChange3 } />
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Nro de dueños</label>
                                                <div>
                                                    <Select options={ duenos } value={ car.nro_duenos } name="nro_duenos" style={{ width: '100%'}} showSearch placeholder="Seleccione" onChange={ guardarDuenos }></Select>
                                                </div> 
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Estado del vehículo</label>
                                                <div>
                                                    <Select options={ estados } value={ car.estado_general } name="estado_general" style={{ width: '100%'}} showSearch placeholder="Seleccione" onChange={ guardarEstado }></Select>
                                                </div>                                                
                                            </div> 
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div><Checkbox onClick={ guardarElemento } name="kit_carretera" checked={ car.kit_carretera }>¿Tiene kit de carretera?</Checkbox></div>
                                            <div><Checkbox onClick={ guardarElemento } name="llanta_repuesto" checked={ car.llanta_repuesto }>¿Tiene llanta de repuesto?</Checkbox></div>
                                            <div><Checkbox onClick={ guardarElemento } name="perno_seguridad" checked={ car.perno_seguridad }>¿Tiene perno de seguridad?</Checkbox></div>
                                            <div><Checkbox onClick={ guardarElemento } name="herramientas" checked={ car.herramientas }>¿Tiene herramientas?</Checkbox></div>
                                            <div><Checkbox onClick={ guardarElemento } name="gato" checked={ car.gato }>¿Tiene gato?</Checkbox></div>
                                        </CCol>                                      
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>
                                            <div><Checkbox onClick={ guardarElemento } name="asegurado" checked={ car.asegurado }>¿Está asegurado?</Checkbox></div>
                                            {
                                                car.asegurado ? 
                                                <div className='form-group'>
                                                    <label>Observación de seguro</label>
                                                    <TextArea rows={4} defaultValue={ car.asegurado_obs } name="asegurado_obs" onBlur={guardarElemento}/>
                                                </div> : null
                                            } 
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div><Checkbox onClick={ guardarElemento } name="prenda" checked={ car.prenda }>¿Tiene prenda?</Checkbox></div>
                                            {
                                                car.prenda ? 
                                                <div className='form-group'>
                                                    <label>Observación de prenda</label>
                                                    <TextArea rows={4} defaultValue={ car.prenda_obs } name="prenda_obs" onBlur={guardarElemento}/>
                                                </div> : null
                                            } 
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div><Checkbox onClick={ guardarElemento } name="mantenimiento" checked={ car.mantenimiento }>¿Mantenimiento?</Checkbox></div>
                                            {
                                                car.mantenimiento ? 
                                                <div className='form-group'>
                                                    <label>Observación de mantenimiento</label>
                                                    <TextArea rows={4} defaultValue={ car.mantenimiento_obs } name="mantenimiento_obs" onBlur={guardarElemento}/>
                                                </div> : null
                                            }
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={6} xs={12}>                                            
                                            <div className='form-group'>
                                                <label>Estado ruedas delanteras(%)</label>
                                                <Slider marks={marks} value={car.estado_llanta_del} min={0} max={100} step={10} tooltip={{ open: true }} onChange={ changeRuedasDel } />
                                            </div>
                                        </CCol>
                                        <CCol md={6} xs={12}> 
                                            <div className='form-group'>
                                                <label>Estado ruedas traseras(%)</label>
                                                <Slider marks={marks} value={car.estado_llanta_tra} min={0} max={100} step={10} tooltip={{ open: true }} onChange={ changeRuedasTra } />
                                            </div>                                            
                                        </CCol>
                                    </CRow>
                                    <CRow className='mb-3'>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Precio</label>
                                                <p className='mb-0'>COP { car.price ? moneyFormat(car.price) : moneyFormat(0) }</p>
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Precio mínimo</label>
                                                <input name="precio_min" className='form-control' defaultValue={ car.precio_min } onBlur={guardarElemento} />
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Porcentaje de comisión</label>
                                                <input name="porc_comision" className='form-control' defaultValue={ car.porc_comision } onBlur={guardarElemento} />
                                            </div>
                                        </CCol>
                                        <CCol md={3} xs={12}>
                                            <div className='form-group '>
                                                <label>Placa</label>
                                                <input name="plate" className='form-control' defaultValue={ car.plate } onBlur={guardarElemento} />
                                            </div> 
                                        </CCol>
                                    </CRow>   
                                    <CRow className='mb-3'>
                                        <CCol md={6} xs={12}>
                                            <div className='form-group '>
                                                <label>Propietario</label>
                                                <Select options={ clients } value={ car.propietario } name="propietario" style={{ width: '100%'}} showSearch placeholder="Seleccione" onChange={ guardarPropietario }></Select>
                                            </div> 
                                            {
                                                car.propietario ? 
                                                <div className='form-group'>
                                                    <label>Observación de propietario</label>
                                                    <TextArea rows={4} defaultValue={ car.observaciones_prop } name="observaciones_prop" onBlur={guardarElemento}/>
                                                </div> : null
                                            }
                                        </CCol>
                                    </CRow>                               
                                </Card>
                            </CCol>
                        </CRow>
                        {
                            car.nro_abonos ?
                            <CRow>
                                <CCol xs={12}>
                                    <div className='form-group'>
                                        <label>
                                            Estado de venta&nbsp;
                                            <Link to={"/car-market/contado/editar/"+car.cm_id}>
                                                <FormOutlined className='iconCameraTable2' title='Editar'/>
                                            </Link>
                                        </label>
                                    </div>
                                </CCol>
                                <CCol xs={12}>
                                    <CRow>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Comprador</label>
                                                <p className="mb-0">{ moneyFormat(car.id_comprador) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Vendedor</label>
                                                <p className="mb-0">{ moneyFormat(car.id_vendedor) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Tipo de separacion</label>
                                                <p className="mb-0">{ (car.tipo_separacion) }</p>
                                            </div>
                                        </CCol>
                                        <CCol xs={3}>
                                            <div className='form-group'>
                                                <label>Responsabilidad de gastos</label>
                                                <p className="mb-0">{ (car.resp_gastos) }</p>
                                            </div>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow> : null
                        }
                        <CRow>
                            <CCol xs={12}>
                                <Collapse accordion>
                                    <Panel header="Descripción mercado libre" key="1">
                                        <ReactQuill value={ car.description } readOnly />
                                    </Panel>
                                </Collapse>
                            </CCol>
                        </CRow>
                        {
                            car.plate ? 
                            <CRow>
                                <CCol xs={12}>
                                    <Collapse accordion>
                                        <Panel header="Archivos" key="1">
                                            <Archivos ref={archivosRef} car_id={ id } placa={ car.plate } />
                                            <Upload {...props}>
                                                <Button icon={<UploadOutlined />}>Seleccione un archivo</Button>
                                            </Upload>
                                            <Button
                                                type="primary"
                                                onClick={handleUpload}
                                                disabled={fileList.length === 0}
                                                loading={uploading}
                                                style={{
                                                    marginTop: 16,
                                                }}
                                            >
                                                {uploading ? 'Subiendo' : 'Iniciar subida'}
                                            </Button>
                                        </Panel>
                                    </Collapse>
                                </CCol>
                            </CRow>: null
                        }
                                                
                        <CRow>
                            <CCol xs={12}>
                                <Collapse accordion>
                                    <Panel header="Observaciones" key="1">
                                        <textarea rows={4} name='observaciones' className='form-control' onBlur={guardarElemento} >{ car.observaciones }</textarea>
                                    </Panel>
                                </Collapse>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={12}>
                                <Collapse accordion>
                                    <Panel header="Detalles para publicar" key="1">
                                        <textarea rows={4} name='detalles' className='form-control' onBlur={guardarElemento}>{ car.detalles }</textarea>
                                    </Panel>
                                </Collapse>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol xs={12}>
                                <Collapse accordion>
                                    <Panel header="Traslados" key="1">
                                        <Table dataSource={traslados} columns={columnsTraslados}  locale={{ emptyText: 'El vehículo no tiene registro de traslados.' }} />;
                                    </Panel>
                                </Collapse>
                            </CCol>
                        </CRow>
                    </form>                    
                </CCol>                
                <CCol xs={12}>
                    <hr />
                </CCol>
                <CCol xs={12}>
                    <div className='tet-center'>
                        <span className="badge text-bg-warning mt-3">Recuerde que la primera imagen del listado es la que corresponde a la portada.</span>
                    </div>
                    <div className="mb-3">                        
                        <label className="form-label">Selecciona la(s) foto(s)</label>
                        <input ref={fileInputRef} className="form-control" type="file" id="formFile" multiple onChange={handleImageUpload} />
                        <button type='button' onClick={ actualizarImagenes } className='btn btn-secondary mt-2'>Actualizar/ Subir fotos</button>
                    </div>
                    {
                        photos.length > 0 ?
                        <DndProvider backend={HTML5Backend}>
                            <CRow>
                            {
                                photos.map((card, i) => renderCard(card, i))
                            }
                            </CRow>   
                        </DndProvider> : ''
                    }
                </CCol>
            </CRow>            
        </>
    )
}

export default Edit;
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import VehiculosService from "src/services/VehiculosService";
import { Table, Space } from 'antd';
 
const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
        title: 'Ver',
        key: 'action',
        render: (_, record) => (
          <>
            <Space size="middle">
              <a href={record.url} target='_blank'>Ver</a>
            </Space>
          </>          
        ),
    },
];

const Archivos = forwardRef(({ car_id, placa }, ref) => {
  const [archivos, setArchivos] = useState([]);
  let _files = []

  const getCar = () =>{
    VehiculosService.getArchivos(car_id).then(resp => {
      let _resp = resp.data ? resp.data : []
      _files = _resp;
      setArchivos(_files)
    })
  }

  useEffect(() => {
    getCar();
  }, [car_id]);

  useImperativeHandle(ref, () => ({
    reload: getCar,
  }));

  return(
    <Table dataSource={archivos} columns={columns}  locale={{ emptyText: 'El vehículo no tiene archivos agregados.' }} pagination={{
        defaultPageSize: 5,
    }}/>
  );
});

export default Archivos;
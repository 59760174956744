import React, {useState, useEffect} from 'react'
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component'
import Moment from 'moment'
import ActasService from 'src/services/ActasService';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";

const Index = () => {
    const columns = [
        {
            name: 'Fecha',
            selector: rowData => Moment(rowData.created_at).format("DD/MM/YYYY h:mm:ss a")
        }, 
        {
            name: 'Cliente',
            selector: rowData => rowData.nombres_cliente
        },
        {
            name: 'Cédula',
            selector: rowData => rowData.cedula_cliente
        },
        {
            name: 'Teléfono',
            selector: rowData => rowData.telefono_cliente
        },
        {
            name: 'Vehículo',
            selector: rowData => rowData.marca+" "+rowData.modelo+" "+rowData.linea
        },
        {
            name: 'Placa',
            selector: rowData => <strong>{rowData.placa}</strong>
        },
        {
            name: 'Creado por',
            selector: row => row.nombres,
            sortable: true,
        }, 
        {
            name: 'Enlace',
            selector: row => <a rel="noreferrer" href={row.url} target='_blank'>Descargar acta</a>
        }
    ];
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const authUser = useAuthUser();
    const [filters, setFilters] = useState({
        "search" : ''
    })
    const navigate = useNavigate();

    const handlePageChange = page => {
        getData(page);
        setPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {  
        setLoading(true)
        setLimit(newPerPage)
        getData(page);    
        setLoading(false)
    };

    const getData = (ppage) =>{
        setLoading(true);
        let _filter = '';
        if(filters.search !== '') _filter += '&search='+filters.search;
        ActasService.getAll(ppage,limit,skip,_filter, 'recepcion').then(resp =>{
            let _data = resp.data;
            setTotalRows(resp.total);
            setData(_data);
            setLoading(false);
        })
    }

    const saveField = (event) =>{
        let _filters = filters;
        switch (event.target.name) {
          case 'search':
            filters.search = event.target.value
            break;
          default:
            break;
        }
        setFilters(_filters)
        getData(1)
    }

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getData(page, limit, skip);
    }, []);

    return(
        <Row>
            <Col span={24} className='text-center my-4'>
                <h3>Listado de Actas de recepción</h3>
            </Col>
            <Col span={24} className='text-end'>
                <Link to={"/actas-recepcion/crear"} className='btn btn-primary'>Crear Acta</Link>
            </Col>
            <Col span={24} className='text-end'>
                <div className='form-group w-100 text-end'>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <input type="search" className='form-control m-150' name="search" defaultValue={ filters.search } onChange={saveField} placeholder="Buscar..." />
                    </span>
                </div>
            </Col>
            <Col span={24} className='my-4'>
                <DataTable
                    noDataComponent={"No se han encontrado resultados"}
                    columns={ columns }
                    data={data}
                    progressPending={loading}
                    pagination 
                    paginationServer
                    responsive             
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            </Col>
        </Row>
    )
}

export default Index;

import React, {useRef, useEffect, useState} from 'react'
import { Form, Button, Col, Input, Checkbox, message, Upload } from 'antd';
import { CRow } from '@coreui/react';
import ActasService from 'src/services/ActasService';
import VehiculosService from 'src/services/VehiculosService';
import SignaturePad from 'react-signature-pad-wrapper';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import { Stage, Layer, Circle, Image } from 'react-konva';
import imageUrl from "../../assets/images/carro.png"
import Archivos from '../vehiculos/Archivos';
import useImage from 'use-image';
import Lottie from "lottie-react";
import gifJson from "../../assets/images/loading.json";
import $ from "jquery";
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const CreateActa = () => {
    const [form] = Form.useForm();
    const signaturePadRef = useRef(null);
    const signaturePadEmpRef = useRef(null);
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const [image] = useImage(imageUrl);
    const [points, setPoints] = useState([]);
    const [placa, setPlaca] = useState('');
    const [carId, setCarId] = useState(0);
    const stageRef = useRef(null);
    const [showLot, setShowLot] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [formData, setFormData] = useState(
        JSON.parse(localStorage.getItem('formData')) || {}
    );
    const archivosRef = useRef();

    const props = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
    };

    const handleStageClick = (e) => {
        const stage = stageRef.current;
        if (stage) {
            // Obtén la posición del cursor en el canvas
            const pointerPosition = stage.getPointerPosition();
            
            // Agrega el nuevo punto a la lista de puntos
            if (pointerPosition) {
                setPoints([...points, { x: pointerPosition.x, y: pointerPosition.y }]);
            }
        }
    };

    const convertURIToBlob = (uri) => {
        return fetch(uri)
          .then((res) => res.blob());
    };

    const onFinish = async (values) => {
        if (signaturePadRef.current && signaturePadRef.current.isEmpty()) {
            message.error('Debe agregar una firma');
            return;
        }
    
        // Desplazarse al inicio de la página
        $('body,html').stop(true, true).animate({
            scrollTop: $('body').offset().top
        }, 100);
    
        setShowLot(true);
    
        try {
            // Convertir el canvas a URI y luego a blob
            const uri = stageRef.current.toDataURL();
            const blob = await convertURIToBlob(uri);
    
            // Crear el objeto con los valores a enviar
            const _data = {
                ...values,
                soat: Boolean(values.soat),
                tecnicomecanica: Boolean(values.tecnicomecanica),
                tarjeta: Boolean(values.tarjeta),
                manuales: Boolean(values.manuales),
                herramientas: Boolean(values.herramientas),
                perno_seguridad: Boolean(values.perno_seguridad),
                repuesto: Boolean(values.repuesto),
                kit_carretera: Boolean(values.kit_carretera),
                botiquin: Boolean(values.botiquin),
                gato: Boolean(values.gato),
                cant_llaves: values.cant_llaves,
                km_inicial: values.km_inicial,
                observaciones: values.observaciones,
                created_by: authUser().id,
                firma_empleado: signaturePadEmpRef.current.toSVG(),
                firma_cliente: signaturePadRef.current.toSVG(),
                tipo: 'recepcion'
            };
    
            // Crear FormData para enviar la información
            const formData = new FormData();
            Object.keys(_data).forEach((key) => formData.append(key, _data[key]));
            formData.append('image', blob, 'canvas-image.png');
    
            // Guardar el acta
            const resp = await ActasService.save(formData);
    
            // Subir los archivos de rayones
            const _dataRayones = {
                name: `Acta_de_recepcion_${values.placa}`,
                url: `https://intranet.triplo.com.co/pdfs/Acta_de_recepcion_${values.placa}.pdf`,
                user_id: authUser()?.id
            };
    
            await VehiculosService.setArchivos(_dataRayones, resp.id);
    
            message.success('Acta de recepción creada exitosamente');
    
            // Redirigir después de 4 segundos
            setTimeout(() => {
                localStorage.removeItem('formData');
                setShowLot(false);
                navigate('/actas-recepcion');
            }, 4000);
    
        } catch (error) {
            message.error('Hubo un error al guardar el acta. Intente nuevamente.');
            setShowLot(false);
        }
    };
    

    const searchPlate = (event) =>{
        if(event.target.id === 'basic_placa' && event.target.value.length === 6){
            ActasService.getByPlate(event.target.value).then(resp =>{
                setPlaca(event.target.value)
                let _data = resp.data                
                if(_data.length > 0){
                    _data = _data[0]
                    setCarId(_data.id);
                    let _fields = {
                        marca: _data.make,
                        linea: _data.model,
                        version: _data.version,
                        anho: _data.year,
                        km_inicial: _data.kilometers
                    }
                    form.setFieldsValue(_fields)
                }
            })
        }
    }

    const clearSignature = () => {
        if (signaturePadRef.current) {
          signaturePadRef.current.clear();
        }
    };

    const clearSignatureEmp = () => {
        if (signaturePadEmpRef.current) {
            signaturePadEmpRef.current.clear();
        }
    };

    const clearRectangles = () => {
        const newArray = points.slice(0, -1);
        setPoints(newArray);
    };
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        localStorage.setItem('formData', JSON.stringify(form.getFieldsValue()));
    };

    const handleUpload = async () => {    
        setUploading(true);
        let names = [];
        const uploadPromises = fileList.map((element, index) => {
            const data = new FormData();
            data.append('file', element);
            names[index] = element.name;
            return VehiculosService.uploadFile(data, `archivos_${placa}`);
        });

        // Espera a que todas las imágenes se suban
        const responses = await Promise.all(uploadPromises);
        
        // Extrae las URLs de las imágenes
        const images = responses.map(response => response.uri);
        
        images.forEach((element, index) => {
            let _data = {
                name: names[index],
                url: element,
                user_id: authUser()?.id              
            }
            VehiculosService.setArchivos(_data, carId).then(resp =>{
                setFileList([]);
                message.success('Archivo(s) subidos correctamente.');
                setUploading(false);
                if (archivosRef.current) {
                    archivosRef.current.reload();
                }
            })
        });
    };

    // useEffect(() => {
    //     localStorage.setItem('formData', JSON.stringify(form.getFieldsValue()));
    // }, [form]);
    
    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }if(localStorage.getItem('formData')){
            //JSON.parse(localStorage.getItem('formData'))
            form.setFieldsValue(JSON.parse(localStorage.getItem('formData')))
        }else{
            let _fields = {
                cedula: authUser().cedula,
                nombres: authUser().names,
                telefono: authUser().phone,
                direccion: 'Cra 13a #93-91'
            }
            form.setFieldsValue(_fields)
        }
    }, []);

    return(
            <div className='no-select'>
                <div className={ showLot ? 'fondoLottie showL' : 'fondoLottie no-showL'}>
                <Lottie animationData={gifJson} loop={true} className="loadLottie" /> 
            </div> 
            <CRow>
                <Col span={24} className='text-center my-4'>
                    <h1>Crear acta de recepción</h1>
                </Col>
            </CRow>
            <Form name="basic" form={form} onFinish={onFinish} layout='vertical' onChange={ searchPlate }>
                <CRow>
                    <Col md={24} xs={24}>
                        <h4>Información del empleado</h4>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Nombres y Apellidos" name="nombres"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Nombres y apellidos' onChange={ handleChange } />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Cédula de ciudadania" name="cedula"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Cédula de ciudadania' onChange={ handleChange } />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Teléfono" name="telefono"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 3134569875' onChange={ handleChange }/>
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Dirección" name="direccion"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ingrese la dirección' onChange={ handleChange } />
                        </Form.Item>
                    </Col>                    
                </CRow>
                <hr/>
                <CRow>
                    <Col md={24} xs={24}>
                        <h4>Información del cliente</h4>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Nombres y Apellidos" name="nombres_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Nombres y apellidos' onChange={ handleChange } />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Cédula de ciudadania" name="cedula_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Cédula de ciudadania' onChange={ handleChange } />
                        </Form.Item>
                    </Col> 
                    <Col md={12} xs={24}>
                        <Form.Item label="Teléfono" name="telefono_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 3134569875' onChange={ handleChange }/>
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Dirección" name="direccion_cliente"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ingrese la dirección' onChange={ handleChange }/>
                        </Form.Item>
                    </Col>
                </CRow>
                <hr/>
                <CRow>
                    <Col md={8} xs={24}>
                        <Form.Item label="Placa" name="placa"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: RNR549' onChange={ handleChange }/>
                        </Form.Item>
                    </Col> 
                    <Col md={8} xs={24}>
                        <Form.Item label="Marca" name="marca"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Mercedes-Benz' onChange={ handleChange }/>
                        </Form.Item>
                    </Col> 
                    <Col md={8} xs={24}>
                        <Form.Item label="Año" name="modelo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: 2022' onChange={ handleChange }/>
                        </Form.Item>
                    </Col> 
                    <Col md={8} xs={24}>
                        <Form.Item label="Linea" name="linea"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: SLK 200' onChange={ handleChange }/>
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Version" name="version"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido!',
                                }
                            ]}>
                            <Input type='text' placeholder='Ej: Clase SLK' onChange={ handleChange }/>
                        </Form.Item>
                    </Col>
                </CRow>
                <hr />
                <CRow>
                    <Col md={24} xs={24}>
                        <p>En este acto, se hace entrega material del vehículo con los siguientes documentos o inventario: </p>
                    </Col>
                    <Col md={24} xs={24}>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th> Documento </th>
                                        <th className='text-center'> Vigente </th>
                                    </tr>
                                </thead>
                                <tbody className='table-group-divider'>
                                    <tr>
                                        <td>SOAT (solicitar copia)</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="soat" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Revisión Tecnicomecanica</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="tecnicomecanica" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col md={24} xs={24}>
                        <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th colSpan={2} className='text-center'>INVENTARIO DEL VEHICULO </th>
                                    </tr>
                                </thead>
                                <tbody className='table-group-divider'>                                    
                                    <tr>
                                        <td>Tarjeta de Propiedad</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="tarjeta" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Manuales del Propietario</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="manuales" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Herramientas del Vehículo</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="herramientas" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Llave de pernos de seguridad</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="perno_seguridad" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Llanta de Repuesto </td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="repuesto" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Kit de Carretera </td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="kit_carretera" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Botiquín</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="botiquin" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Gato</td>
                                        <td className='text-center'>
                                            <Form.Item className='my-0' label="" name="gato" valuePropName="checked">
                                                <Checkbox onChange={ handleChange }></Checkbox>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center' colSpan={2}>
                                            <Form.Item className='my-0' label="Llaves" name="cant_llaves"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Este campo es requerido!',
                                                    }
                                                ]}>
                                                <Input type='number' className='text-end' placeholder='Ej: 2' onChange={ handleChange }/>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center' colSpan={2}>
                                            <Form.Item className='my-0' label="Kilometraje inicial" name="km_inicial" 
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Este campo es requerido!',
                                                    }
                                                ]}>
                                                <Input type='text' className='text-end' placeholder='Ej: 10000' onChange={ handleChange } />
                                            </Form.Item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-center' colSpan={2}>
                                            <Form.Item className='my-0' label="Otros/ Observaciones adicionales" name="observaciones">
                                                <TextArea rows={2} onChange={ handleChange }/>
                                            </Form.Item>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </CRow>
                <hr />
                <CRow className='my-3'>
                    <Col xs={24} span={24} className='text-center mx-auto'>
                        <Stage
                                width={380}
                                height={318}
                                onTouchStart={handleStageClick}
                                onClick={handleStageClick}
                                ref={stageRef}
                            >
                                <Layer>
                                    {image && <Image image={image} />}
                                    {points.map((point, index) => (
                                        <Circle
                                            key={index}
                                            x={point.x}
                                            y={point.y}
                                            radius={5}
                                            fill="black"
                                        />
                                    ))}
                                </Layer>
                            </Stage>                       
                    </Col>
                    <Col xs={24} span={24} className='text-center'>
                    {
                        points.length > 0 ? <button type='button' onClick={clearRectangles}>Borrar</button> : null
                    }                        
                    </Col>
                </CRow>
                <CRow className="ffirmas">
                    <Col span={24} xs={24} className='text-center mx-auto'>
                        <p className='mb-0'><strong>Firma del cliente</strong></p>
                        <SignaturePad width={400} height={200} ref={signaturePadRef} 
                            options={{
                                penColor: 'rgb(0, 0, 0)',
                                minWidth: 0.5, // Grosor mínimo del trazo
                                maxWidth: 2.5, // Grosor máximo del trazo
                                throttle: 16,  // Reduce los eventos para mejorar la suavidad
                                velocityFilterWeight: 0.7 // Ajusta el peso de la velocidad del trazo
                            }} />
                    </Col>
                    <Col span={24} xs={24} className='text-center'>
                        <button type='button' onClick={clearSignature}>Borrar firma</button>
                    </Col>
                </CRow>
                <CRow className="ffirmas mt-4">
                    <Col span={24} xs={24} className='text-center mx-auto'>
                        <p className='mb-0'><strong>Firma del empleado</strong></p>
                        <SignaturePad width={400} height={200} ref={signaturePadEmpRef} 
                        options={{
                            penColor: 'rgb(0, 0, 0)',
                            minWidth: 0.5, // Grosor mínimo del trazo
                            maxWidth: 2.5, // Grosor máximo del trazo
                            throttle: 16,  // Reduce los eventos para mejorar la suavidad
                            velocityFilterWeight: 0.7 // Ajusta el peso de la velocidad del trazo
                        }}  />   
                    </Col>
                    <Col span={24} xs={24} className='text-center'>
                        <button type='button' onClick={clearSignatureEmp}>Borrar firma</button>
                    </Col>
                </CRow>
                {
                    placa != '' ?
                    <CRow className='my-4'>
                        <Col span={24} xs={24} md={24} className='text-center'>
                            <label>Si desea agregar archivos adicionales puede hacerlo aqui, <strong>OJO: Estos archivos quedan asociados a la placa del vehiculo.</strong></label>
                            <Archivos ref={archivosRef} car_id={ carId } placa={ placa } />
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Seleccione un archivo</Button>
                            </Upload>
                            <Button
                                type="primary"
                                onClick={handleUpload}
                                disabled={fileList.length === 0}
                                loading={uploading}
                                style={{
                                    marginTop: 16,
                                }}
                            >
                                {uploading ? 'Subiendo' : 'Iniciar subida'}
                            </Button>
                        </Col>
                    </CRow> : null
                }
                <CRow className='my-4'>
                    <Col span={24} xs={24} md={24} className='text-center'>
                        <Button type='primary'  htmlType="submit">Generar</Button>
                    </Col>
                </CRow>
            </Form>
        </div>    
    )
}

export default CreateActa;

import { CCol, CRow } from '@coreui/react';
import React ,{ useEffect, useState } from 'react'
import VentasService from 'src/services/Ventas'
import { useAuthUser } from 'react-auth-kit';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Button, message } from 'antd';

const Ventas = () => {
  const [data, setData] = useState([]);
  const authUser = useAuthUser();
  const [globalFilter, setGlobalFilter] = useState(null);
  const kmFormat = (value) => new Intl.NumberFormat().format(value)+" KM";
  const moneyFormat = (value) => new Intl.NumberFormat().format(value)+" COP";
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [])

  const getData = () =>{
    VentasService.getAll().then(resp =>{
      let _data = resp.data;
      if(authUser().rol === 2){
        setData(_data.filter(x => x.origin === 'alfred'));
      }else{
        setData(_data);
      }
      setTimeout(() => {
        setIsLoading(false)
      }, 1000);
    })
    .catch(error => console.log(error));
  }

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between text-end">
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <input type="search" className='form-control' onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
        </span>
    </div>
  );

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} severity={getSeverity(rowData)}></Tag>;
  };

  const urlBodyTemplate = (rowData) => {
    return <a href={ "https://intranet.triplo.com.co/pdfs/"+rowData.id+"_"+rowData.phone+".pdf"} target='_blank'>PDF</a>;
  };

  const priceOfertBodyTemplate = (rowData) => {
    return moneyFormat(rowData.price_offer);
  };

  const getSeverity = (product) => {
    switch (product.status) {
        case 'Excelente':
            return 'success';

        case 'Muy Bueno':
            return 'success';

        case 'Bueno':
            return 'warning';

        case 'Semi-Bueno':
          return 'warning';

        case 'Regular':
          return 'danger';

        default:
            return null;
    }
  };

  const kmBodyTemplate = (rowData) => {
    return kmFormat(rowData.km);
  };

  const actionBodyTemplate = (rowData) => {
    return <div className='d-flex'>
      {
        rowData.status_proceso !== 'No estoy interesado'  ?
        <div className='text-center cc1'>
        <Button type="primary" onClick={() => cambiarEstado(rowData.id)}>
          Cambiar a "No interesado"
        </Button>
      </div> : null
      }      
    </div>
  }

  const cambiarEstado = (id) => {
    VentasService.changeStatus(id).then(resp =>{
      getData()
    })
  };

  const handleCopy = (text) => {
      navigator.clipboard.writeText(text);
      message.success("Celular: "+text+" copiado!");
  };

  return(
    <>
      <CRow>
        <CCol xs={12} className='text-center mb-4'>
            <h2>Ventas</h2>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <DataTable size={'small'} loading={ isLoading } emptyMessage={ "No se han encontrado resultados" } value={data} tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[10, 25, 50, 100, 0]}
           currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"  globalFilter={globalFilter} header={header}>
              <Column field="created_at" header="Fecha" sortable filter></Column>
              <Column field="names" header="Nombres" sortable  filter></Column>
              <Column field="phone" header="Celular" body={(rowData) => (
                  <span onClick={() => handleCopy(rowData.phone)}>
                    {rowData.phone}
                  </span>
                )} sortable  filter>
              </Column>
              <Column field="vehicle" header="Vehículo" sortable filter></Column>
              <Column field="km" body={ kmBodyTemplate } header="KM" sortable  filter></Column>
              <Column field="status" header="Estado" body={ statusBodyTemplate } sortable style={{ minWidth: '12rem' }}></Column>
              <Column field="price_offer" body={ priceOfertBodyTemplate } header="Oferta" sortable  filter></Column>
              <Column field="sale_type" hidden={ authUser().rol === 2}  header="Tipo" sortable filter></Column>
              <Column field="status_proceso" header="Estado Proceso" sortable filter></Column>
              <Column field="pdf" body={ urlBodyTemplate } header="PDF" sortable filter></Column>
              <Column headerStyle={{ width: '5rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} hidden={ authUser().rol !== 2} />
          </DataTable>
        </CCol>
      </CRow>
    </>
  )
}

export default Ventas

import React ,{ useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { CRow } from '@coreui/react';
import { Col, Space, Switch } from 'antd';
import VehiculosService from 'src/services/VehiculosService';
import { useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import RetomasService from 'src/services/Retoma';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

const VerRetoma = () => {
    const moneyFormat = (value) => new Intl.NumberFormat().format(value);
    const [vehicleFoto, setVehicleFoto] = useState([]);
    const [dataDef, setdataDef] = useState({
        estado: 'por aprobar'
    });
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const { id } = useParams();
    const [index, setIndex] = useState(-1);
    var currentImage = vehicleFoto[index];
    const nextIndex = (index + 1) % vehicleFoto.length;
    const nextImage = vehicleFoto[nextIndex] || currentImage;
    const prevIndex = (index + vehicleFoto.length - 1) % vehicleFoto.length;
    const prevImage = vehicleFoto[prevIndex] || currentImage;

    useEffect(() => {
        if(!authUser().id){
            navigate('/')
        }
        getVehicles();
    }, [])

    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    const getVehicles = () =>{
        VehiculosService.getActives().then(resp =>{
            let items = [];
            resp.data.forEach(element => {
                items.push({ value: element.id, label: element.title })
            });
            RetomasService.getOne(id).then(item =>{
                let _resp = item.data[0];
                let _images = [];
                item.data_fotos.forEach(element => {
                    _images.push({
                        src: element.foto,
                        original: element.foto,
                        width: 500,
                        caption: element.foto,
                    })
                });
                setVehicleFoto(_images)
                setdataDef(_resp)             
            })
        })
    }

    const cancelar = () =>{
        RetomasService.cancelar(id).then(item =>{
            setTimeout(() => {
                getVehicles(); 
            }, 1000);   
        })
    }
    
    const handleClick = (index) => {
        currentImage = vehicleFoto[index]
        setIndex(index);
    }
    
    const volver = () =>{
        navigate("/retomas/")
    }

    const handleChangeOfertado  = (id, checked) => {
        let _data = {
            'isOfertado': checked
        }
        RetomasService.updateOfertado(_data, id).then(() => getVehicles())
    };

    const getEstado = (estado) => {
        let badge = '';
        switch (estado) {
            case 'modificado':
                badge = 'warning';
                break;
            case 'por aprobar':
                badge = 'warning';
                break;
            case 'rechazado':
            case 'cancelado':
                badge = 'danger';
                break;
            case 'aprobado':
                badge = 'success';
                break;
            default:
                break;
        }
        return <span className={'badge rounded-pill text-bg-'+badge}>{estado.toUpperCase()}</span>;
    };
    
    return(
        <>
            <CRow>
                <Col md={24} xs={24} className='text-center mb-4'>
                    <h2>Ver retoma </h2>                   
                </Col>
            </CRow>
            <hr />
            <CRow>
                <Col md={12} xs={24}>
                    <ul>
                        <li><p className='neta'>Diferencia neta: <strong>{ moneyFormat(dataDef.diferencia_neta) } COP</strong></p></li>
                        <li>Vehiculo en venta: <strong>{ dataDef.title ? dataDef.title : dataDef.vehiculo_venta ?  dataDef.vehiculo_venta : '' }</strong></li>
                        <li>Precio referencia vehiculo en venta: <strong>{ moneyFormat(dataDef.precio_ref_veh_venta) } COP</strong></li>
                        <li>Vehiculo a retomar: <strong>{ dataDef.vehiculo_retoma }</strong></li>
                        <li>KM vehiculo a retomar: <strong>{ moneyFormat(dataDef.km_vehiculo_retoma) } KM</strong></li>
                        <li>Precio estimado de venta: <strong>${ moneyFormat(dataDef.precio_estimado_venta) }</strong></li>
                        <li>Precio referencia vehiculo a retomar: <strong>{ moneyFormat(dataDef.precio_ref_veh_retoma) } COP</strong></li>
                        <li>Promedio de venta: <strong>{ dataDef.promedio_tiempo_venta }</strong></li>                        
                        <li>Link tucarro: <strong><a href={ dataDef.link_tucarro_referencia} target='_blank'>TuCarro</a></strong></li>
                        <li>Observaciones: <strong>{ dataDef.observaciones !== "undefined" ? dataDef.observaciones : 'Ninguna' }</strong></li>
                        <li>Utilidad esperada: <strong>${ moneyFormat(dataDef.utilidad_esperada) }</strong></li>
                        <li>Rentabilidad: <strong>{ dataDef.rentabilidad }%</strong></li>
                        <li>Creado por: <strong>{ dataDef.user }</strong></li>
                        <li>Modificado por: <strong>{ dataDef.user_mod }</strong></li>
                        <li>Estado: <strong>{ getEstado(dataDef.estado) }</strong></li>
                        <li>¿Fue ofertado? &nbsp;&nbsp;
                            <Space direction="vertical">
                                <Switch checkedChildren="Si" unCheckedChildren="No" checked={dataDef.isOfertado} 
                                onChange={(checked) => handleChangeOfertado(dataDef.id, checked)}  />
                            </Space>
                        </li>
                    </ul>
                </Col>
                {
                    vehicleFoto ? 
                    <Col md={12} xs={24} className='text-center mb-4'>
                        <Gallery
                            images={vehicleFoto}
                            onClick={handleClick}
                            enableImageSelection={false}
                        />
                        {!!currentImage && (
                            /* @ts-ignore */
                            <Lightbox
                                mainSrc={currentImage.original}
                                imageTitle={currentImage.caption}
                                mainSrcThumbnail={currentImage.src}
                                nextSrc={nextImage.original}
                                nextSrcThumbnail={nextImage.src}
                                prevSrc={prevImage.original}
                                prevSrcThumbnail={prevImage.src}
                                onCloseRequest={handleClose}
                                onMovePrevRequest={handleMovePrev}
                                onMoveNextRequest={handleMoveNext}
                            />
                        )}
                    </Col> : null
                }
            </CRow>
            <CRow className='mb-4'>
                <Col span={24}  md={24} xs={24} className='text-center'>
                    <button type='button' onClick={ volver } className='btn btn-primary mx-3'>REGRESAR</button>
                    {
                        authUser().id === dataDef.user_id && dataDef.estado !== 'cancelado' ?
                        <button type='button' onClick={ cancelar } className='btn btn-secondary'>CANCELAR RETOMA</button> : ''
                    }
                </Col>
            </CRow>
        </>
    )
}

export default VerRetoma;